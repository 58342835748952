import Styled from 'styled-components';
import Box from '@mui/material/Box';

const TabsWrapper = Styled(Box)`
 // beacause tab take bydefault padding so we use important
 .MuiTabs-root{
   min-height:0px;
 } 
    padding: 0 !important;
    .MuiTabs-flexContainer {
      justify-content:${(props) => (props.center ? 'center' : 'left')};
      gap:12px;
    }
    .MuiTab-root {
                 position: relative;
                  color: #474E58;
                    font-size: 14px;
                    text-transform:capitalize;
                    justify-content:flex-start;
                    font-family: 'Manrope', sans-serif;
                    min-width:110px;
                    padding:${(props) => (props.center ? '8px 12px' : props.customPadding ? props.customPadding : '6px 11px 7px 12px')};
                    min-height:14px;
                    letter-spacing: 0.06px;
              &.Mui-selected {
                     color: #191D23;
                     font-weight:600;
              }
    }
    .MuiTabs-indicator {
         background-color: #0948A7;
         bottom:0px;
    }
`;

export default TabsWrapper;
