import React from 'react';

import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

const App = () => {
  const { graphData } = useSelector((state) => state.dashboard);

  const fillColors = ['#4F46E5', '#818CF8'];
  
  const series=  [{
    name: 'ad Sale',
    data: graphData?.adSale?.map(s => {
      return {
        x: new Date(s[0]).getTime(),
        y: s[1]
      };
    })
  },
  {
    name: 'ad Spend',
    data: graphData?.adSpend?.map(s => {
      return {
        x: new Date(s[0]).getTime(),
        y: s[1]
      };
    })
  }];
  
  const chartOptions = {
    chart: {
      type: 'area',
      stacked: false,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    stroke: {
      curve: 'smooth',
      colors: fillColors,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      show: false
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      markers: {
        fillColors: fillColors,
        radius: 2,
      },
    },
    fill: {
      colors: fillColors,
    },
    dataLabels: {
      enabled: false
    },
  };
  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="area"
        height={288}
      />
    </div>
  );
};

export default App;
