import React from 'react';
import {Box,Stepper,Step,StepLabel } from '@mui/material';
import StepperStyle from './style';

const AppStepper= (props) => {
  const {steps,activeStep } =props;

  return (
    <StepperStyle>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps?.map((step) => (
          <Step key={step.index}>
            <StepLabel
            >
              {step.label}
            </StepLabel>
           
          </Step>
        ))}
 
      </Stepper>
      {steps[activeStep].description}
      <Box sx={{ mb: 2 }}>
        {props.children}
      </Box>
    </StepperStyle>
  );
};

export default AppStepper;
