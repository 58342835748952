import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Input from '../../../components/inputs/input';
import Button from '../../../components/button';
import Select from '../../../components/select';
import {
  connectADSAPIStore,
  resetState,
  updateAccountInfo,
} from '../../../redux/slice/storeSlice';
import { setAuthToken } from '../../../config/axios-configuration';
import {
  REGION_ITEMS,
  ADVERTISING_CLIENT_ID,
  ADVERTISING_REDIRECT_URI,
} from '../../../utils/constants';

const Login = ({ inputChangeHandle }) => {
  const { accountInfo, loading, message, error, store, profiles } = useSelector(
    (state) => state.store
  );
  const isConnected = get(store, 'adsApi.isConnected', false);
  const { melamdo_token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const { name, region, storeType, authCode } = accountInfo;
    if (name && region && storeType && authCode && !isConnected) {
      setAuthToken(melamdo_token);
      dispatch(connectADSAPIStore({ ...accountInfo }));
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          authCode: '',
        })
      );
    }
  }, [accountInfo]);

  useEffect(() => {
    if (error && message && !loading) {
      toast.error(message);
      dispatch(resetState());
    }
  }, [message]);

  useEffect(() => {
    const isConnected = get(store, 'adsApi.isConnected', false);
    if(isConnected && isEmpty(profiles)){
      toast.error('No Profile found in this store');
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          authCode: '',
          activeStep: 0,
        })
      );
    }
    if (isConnected && !isEmpty(profiles)) {
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          authCode: '',
          activeStep: 1,
        })
      );
    }
  }, [store]);
  const submitHandle = () => {
    let url;
    switch (accountInfo.region) {
    case 'eu':
      url = `https://eu.account.amazon.com/ap/oa?client_id=${ADVERTISING_CLIENT_ID}&scope=advertising::campaign_management&response_type=code&redirect_uri=${ADVERTISING_REDIRECT_URI}`;
      break;
    case 'na':
      url = `https://www.amazon.com/ap/oa?client_id=${ADVERTISING_CLIENT_ID}&scope=advertising::campaign_management&response_type=code&redirect_uri=${ADVERTISING_REDIRECT_URI}`;
      break;
    default:
      url = `https://apac.account.amazon.com/ap/oa?client_id=${ADVERTISING_CLIENT_ID}&scope=advertising::campaign_management&response_type=code&redirect_uri=${ADVERTISING_REDIRECT_URI}`;
      break;
    }
    window.open(url, '_self');
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={8}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              mt={3}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="11px 32px"
            >
              <h3>What’s your seller account called?</h3>
              <Box component="span" color="#667085">
                We use your account name to identify your account.
              </Box>
              <Box textAlign="left" mt={3}>
                <Input
                  label="Account Name"
                  name="name"
                  onChange={inputChangeHandle}
                  value={accountInfo.name}
                  height="52px"
                  borderRadius="8px"
                  width="100%"
                />
                {accountInfo.name && (
                  <Select
                    label="Region"
                    placeholder="-Select Region"
                    name="region"
                    value={accountInfo.region}
                    menuItem={REGION_ITEMS}
                    handleChange={inputChangeHandle}
                  />
                )}
                <Box textAlign="right" mt={2}>
                  <Button
                    variant="contained"
                    disabled={!(accountInfo.name && accountInfo.region)}
                    text="Next"
                    width="78px"
                    height="40px"
                    onClick={submitHandle}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default Login;
