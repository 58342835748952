import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import AppRoute from './routes/AppRoutes';
// styles
import GlobalStyles from './config/theme/global.styled';
import theme from './config/theme/themeVariables';
import { setAuthToken } from './config/axios-configuration';

function App() {
  const { melamdo_token } = useSelector((state) => state.auth);
  useEffect(() => {
    if (melamdo_token) {
      setAuthToken(melamdo_token);
    }
  }, [melamdo_token]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppRoute />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
