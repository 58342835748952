import axios from 'axios';

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

const axiosBaseUrl = () => {
  axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:4000': 'https://api.melamdo.com';
  return axios;
};

export { setAuthToken, axiosBaseUrl };
