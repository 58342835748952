import React, { useState, useEffect } from 'react';

import { Box, Grid, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash/isEmpty';

import Logo from '../../static/images/logo.svg';
import Input from '../../components/inputs/input/index';
import Button from '../../components/button/index';
import PreSignUpModal from './preSignUpModal';
import { signIn, resetState } from '../../redux/slice/authSlice';
import {
  updateAccountInfo,
  getAdsStoreProfiles,
  updateStore,
} from '../../redux/slice/storeSlice';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, pre_signup_melamdo_token, melamdo_token } =
    useSelector((state) => state.auth);
  const { stores, accountInfo } = useSelector((state) => state.store);

  const [showPreSignUp, setShowPreSignUp] = useState(false);
  const [inputValues, setInputValues] = useState({ email: '', password: '' });

  useEffect(() => {
    dispatch(resetState());

    return () => {
      dispatch(resetState());
    };
  }, []);
  const updateInCompleteStore = async (filteredStore) => {
    if (filteredStore?.spApi?.marketplaces?.length > 0) {
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          activeStep: 3,
          name: filteredStore.name,
          region: filteredStore.region,
        })
      );
      navigate('/dashboard/performance');
    } else if (filteredStore?.adsApi?.profiles?.length > 0) {
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          activeStep: 2,
          name: filteredStore.name,
          region: filteredStore.region,
        })
      );
      navigate('/onBoarding');
    } else if (filteredStore?.adsApi?.isConnected) {
      await dispatch(getAdsStoreProfiles({ _id: filteredStore._id }));
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          authCode: '',
          activeStep: 0,
          name: filteredStore.name,
          region: filteredStore.region,
        })
      );
      dispatch(updateStore(filteredStore));
      navigate('/onBoarding');
    } else {
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          activeStep: 4,
        })
      );
      navigate('/dashboard/performance');
    }
  };
  useEffect(() => {
    if (melamdo_token) {
      if (!isEmpty(stores) && stores.length > 1) {
        navigate('/dashboard/performance');
      } else {
        const filteredStore = stores[0];
        if (!isEmpty(filteredStore)) {
          updateInCompleteStore(filteredStore);
        } else {
          navigate('/onBoarding');
        }
      }
    }
  }, [melamdo_token]);

  useEffect(() => {
    if (error && message) {
      toast.error(message);
    }
  }, [error, message]);

  const inputChangeHandle = (e) => {
    const { value, name } = e.target || {};
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (
    <>
      <Box textAlign="center" pt={60 / 8}>
        <img src={Logo} alt="no-logo" />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={4}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              textAlign="center"
              mt={74 / 8}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="11px 32px"
            >
              <h2>Welcome back!</h2>
              <Box textAlign="left" mt={8.375}>
                <Input
                  label="Email address"
                  width="100%"
                  name="email"
                  onChange={inputChangeHandle}
                  value={inputValues.email}
                  type="email"
                />
                <Input
                  label="Password"
                  width="100%"
                  type="password"
                  name="password"
                  onChange={inputChangeHandle}
                  value={inputValues.password}
                />
                <Box textAlign="right" mb={3}>
                  <Link
                    className="pointer"
                    underline="none"
                    fontSize="14px"
                    color="#090914"
                    href="/auth/forget-password"
                  >
                    Forgot password?
                  </Link>
                </Box>
                <Button
                  variant="contained"
                  text={
                    loading ? (
                      <div>
                        <CircularProgress
                          color="inherit"
                          sx={{
                            width: '30px !important',
                            margin: '10px 12px 4px 12px',
                            height: '30px !important',
                          }}
                        />
                      </div>
                    ) : (
                      'Sign In'
                    )
                  }
                  width="100%"
                  disabled={!(inputValues.email && inputValues.password)}
                  onClick={() => {
                    dispatch(signIn({ ...inputValues }));
                  }}
                />
                <Box
                  fontSize="14px"
                  color="#71717A"
                  textAlign="center"
                  mt={4.25}
                  mb={14 / 8}
                >
                  Don&apos;t have an account?
                  <Link
                    underline="none"
                    className="pointer"
                    sx={{ paddingLeft: '4px', color: '#0948A7' }}
                    onClick={() => {
                      if (pre_signup_melamdo_token) {
                        navigate('/auth/sign-up');
                      } else {
                        setShowPreSignUp(true);
                      }
                    }}
                  >
                    Sign up
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {showPreSignUp && (
        <PreSignUpModal
          show={showPreSignUp}
          onClose={() => setShowPreSignUp(false)}
        />
      )}
    </>
  );
};
export default Login;
