import React from 'react';

import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Stepper from '../../components/stepper';
import AccountNumber from './component/accountNumber';
import ConnectAmazon from './component/connectAmazon';
import ConnectSeller from './component/connectSeller';
import AccountConnected1 from './component/AccountConnected1';
import Welcome from '../auth/welcome';
import Logo from '../../static/images/logo.svg';
import { updateAccountInfo } from '../../redux/slice/storeSlice';

const Index = () => {
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((state) => state.store);

  const inputChangeHandle = (e) => {
    const { name, value } = e.target || {};
    dispatch(
      updateAccountInfo({
        ...accountInfo,
        [name]: value,
      })
    );
  };

  const setActiveStep = (value) => {
    dispatch(
      updateAccountInfo({
        ...accountInfo,
        activeStep: value,
      })
    );
  };
  
  const steps = [
    {
      label: 'Your Account Name',
      description: (
        <AccountNumber
          setActiveStep={setActiveStep}
          activeStep={accountInfo.activeStep}
          inputChangeHandle={inputChangeHandle}
        />
      ),
    },
    {
      label: 'Connect Amazon Advertising',
      description: (
        <ConnectAmazon
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        />
      ),
    },
    {
      label: 'Connect Selling Partner',
      description: (
        <ConnectSeller
          onClick={() => setActiveStep(3)}
          inputChangeHandle={inputChangeHandle}
        />
      ),
    },
    {
      label: 'Account Connected',
      description: <AccountConnected1 onClick={() => setActiveStep(4)} />,
    },
  ];

  return (
    <Box textAlign="center" pt={60 / 8}>
      {accountInfo.activeStep < 4 ? <img src={Logo} alt="no-logo" /> : ''}

      {accountInfo.activeStep === 4 ? (
        <Welcome
          stepperWelcome
          paragraph="We currently syncing your Amazon account which can take up to 48 hours. You will get an email when the sync is done so you can close the app and come back later."
        />
      ) : (
        <Container sx={{ marginTop: '32px' }}>
          {' '}
          <Stepper steps={steps} activeStep={accountInfo.activeStep} />{' '}
        </Container>
      )}
    </Box>
  );
};

export default Index;
