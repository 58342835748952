import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateAccountInfo } from '../../redux/slice/storeSlice';

function AmazonLogin() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountInfo } = useSelector((state) => state.store);

  const authCode = new URLSearchParams(search)?.get('spapi_oauth_code') || '';
  const sellerId = new URLSearchParams(search)?.get('selling_partner_id') || '';
  const state = new URLSearchParams(search)?.get('state') || '';

  useEffect(() => {
    if (authCode) {
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          authCode: authCode,
          sellerId: sellerId,
          state: state
        })
      );
      navigate('/onBoarding');
    }
  }, [authCode]);
  return null;
}

export default AmazonLogin;
