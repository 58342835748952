import React,{useState} from 'react';
import { Box, Menu, MenuItem, Avatar } from '@mui/material';
import Input from '../inputs/input/index';
import Button from '../button';
import { DateRange } from 'react-date-range';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Index=(props)=>{
  const {pathname} =props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box fontSize="24px" color="#18181B" fontWeight="600">{pathname === '/' ? 'Performance' : pathname} </Box >
      <Box display="flex" gap={2}>
        <Box position="relative">
          <Input marginBottom="0px" dateRange
            height="40px"
            placeholder="04/01/2023 - 04/302023"
            width="222px"
            borderRadius="4px"
            type="text"
            onClick={() => setShowDateRangePicker(!showDateRangePicker)} />
          {showDateRangePicker && (
            <Box position="absolute" boxShadow="0 0 10px #ccc" zIndex="9">
              <DateRange
                editableDateInputs={true}
                ranges={selectedRange}
                onChange={(item) => setSelectedRange([item.selection])}
              />
            </Box>
          )}
        </Box>
        <Button text="Refresh" fontSize="13px" startIcon={<RefreshIcon />} height="40px" />
        <Button text="Learn More" fontSize="13px" height="40px" />
        <Box display="flex" onClick={handleClick} gap={1} alignItems="center" className="pointer">
          <Avatar alt="GL" sx={{ backgroundColor: '#C8ECED', color: '#346C6D' }} src="/static/images/avatar/1.jpg" />
          <KeyboardArrowDownIcon sx={{ color: '#64748B' }} />
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={() => {
            localStorage.clear();
            window.open('/', '_self');
          }}>Logout</MenuItem>
        </Menu>

      </Box>
    </Box>
  );
};
export default Index;