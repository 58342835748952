import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '../../components/button/index';
import Modal from '../../components/modal';
import Input from '../../components/inputs/input/index';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { preSignUp } from '../../redux/slice/authSlice';
import { isValidEmail } from '../../utils/utils';

const SignUp = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector(
    (state) => state.auth
  );

  const [email, setEmail] = useState('');
  return (
    <div>
      <Modal show={show} width={512} onClose={onClose}>
        <Box
          sx={{ position: 'relative', padding: '24px', minWidth: '460px' }}
          className="reinvite-modal"
        >
          <Box
            position="absolute"
            onClick={onClose}
            className="pointer"
            right="21px"
            width="28px"
            height="28px"
            bgcolor="#F4F6FA"
            sx={{ borderRadius: '50px' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ClearOutlinedIcon sx={{ color: '#667085', fontSize: 16 }} />
          </Box>
          {!loading && success ? (
            <>
              <Box
                width="64px"
                height="64px"
                bgcolor="#F4F9FF"
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: '50px' }}
              >
                <Box
                  width="50px"
                  height="50px"
                  bgcolor="#DEEBFD"
                  sx={{ borderRadius: '50px' }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmailOutlinedIcon sx={{ color: '#0948A7' }} />
                </Box>
              </Box>
              <Stack alignItems="flex-start" justifyContent="center">
                <Box
                  component="h3"
                  mb={2.2}
                  className="m-0"
                  fontFamily="'Inter', sans-serif"
                >
                  Check your email
                </Box>
                <Box
                  mb={2}
                  fontSize="14px"
                  color="#667085"
                  fontFamily="'Inter', sans-serif"
                >
                  We’ve sent you an email to confirm your email address, simply
                  tap the <br /> ‘Confirm’ button in the email sent to{' '}
                  <b>{email}</b>
                </Box>
              </Stack>
            </>
          ) : (
            <>
              <Stack alignItems="flex-start" justifyContent="center">
                <Box
                  component="h3"
                  mb={2.2}
                  className="m-0"
                  fontFamily="'Inter', sans-serif"
                >
                  What’s your work email
                </Box>
                <Box
                  mb={2}
                  fontSize="14px"
                  color="#667085"
                  fontFamily="'Inter', sans-serif"
                >
                  We’ll send you an email to confirm your address
                </Box>
              </Stack>
              <Input
                borderRadius="8px"
                marginBottom="16px"
                label="Email"
                height="52px"
                placeholder="example@email.com"
                width="100%"
                onChange={(e) => setEmail(e?.target?.value || '')}
              />
              <Stack pt={2} direction="row" justifyContent="end">
                <Button
                  variant="contained"
                  height="40px"
                  text={
                    loading ? (
                      <div>
                        <CircularProgress
                          color="inherit"
                          sx={{
                            width: '30px !important',
                            margin: '10px 12px 4px 12px',
                            height: '30px !important',
                          }}
                        />
                      </div>
                    ) : (
                      'Submit'
                    )
                  }
                  disabled={!isValidEmail(email)}
                  onClick={() => {
                    dispatch(preSignUp({ email }));
                  }}
                />
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SignUp;
