import React from 'react';

import { Box, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '../../components/button';

import Logo from '../../static/images/logo.svg';
import Welcome from '../../static/images/welcome.svg';
import {
  resetUpdateAccountInfo
} from '../../redux/slice/storeSlice';

const EmailConfirmed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { stores, loading } = useSelector((state) => state.store);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Box textAlign="center" pt={60 / 8}>
          <img src={Logo} alt="no-logo" />
          <Grid container alignItems="center" justifyContent="center">
            <Grid item md={4}>
              <Box
                bgcolor="#fff"
                textAlign="left"
                borderRadius={3}
                mt={74 / 8}
                boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
                padding="24px"
              >
                <img src={Welcome} alt="no-svg" />
                <Stack alignItems="flex-start" mt={3}>
                  <Box
                    component="h3"
                    mb={2.2}
                    className="m-0"
                    fontFamily="'Inter', sans-serif"
                  >
                    Welcome Gady!
                  </Box>
                  <Box
                    mb={2}
                    fontSize="14px"
                    color="#667085"
                    fontFamily="'Inter', sans-serif"
                  >
                    Let’s get started by connecting an Amazon account.
                  </Box>
                </Stack>
                <Stack pt={2} direction="row" justifyContent="end">
                  <Button
                    variant="contained"
                    height="40px"
                    text="Connect Account"
                    onClick={() => {
                      localStorage.setItem(
                        'selectedItem',
                        '/dashboard/performance'
                      );
                      if (stores?.length > 0) {
                        navigate('/dashboard/performance');
                        dispatch(resetUpdateAccountInfo({
                          name: '',
                          region: '',
                          storeType: 'ads',
                          authCode: '',
                          sellerId: '',
                          activeStep: 0,
                          randomState: `${Math.random()}${new Date().getSeconds()}`,
                          state: ''
                        }));
                      }
                    }}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default EmailConfirmed;
