import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import Login from '../container/auth/login';
import EmailConfirmed from '../container/auth/emailConfirmed';
import SignUp from '../container/auth/signUp';
import Welcome from '../container/auth/welcome';
import ForgetPassword from '../container/auth/forgetPassword/forgetPassword';
import ResetPassword from '../container/auth/forgetPassword/resetPassword';
import NewPassword from '../container/auth/forgetPassword/newPassword';
import PasswordUpdate from '../container/auth/forgetPassword/passwordUpdate';
import OnBoarding from '../container/onBoarding';
import LoginPpc from '../container/onBoarding/LoginPpc';
import AmazonLogin from '../container/onBoarding/AmazonLogin';
import Logout from '../layout/Logout';
import Inventory from '../container/dashboard/inventory';
import Information from '../container/dashboard/information';
import Product from '../container/dashboard/products';
import Performance from '../container/dashboard/performance';
import Layout from '../layout';

const ProtectedRoute = ({ children, type }) => {
  const { pre_signup_melamdo_token, melamdo_token } = useSelector(
    (state) => state.auth
  );
  const token = type === 'super' ? melamdo_token : pre_signup_melamdo_token;
  return token ? children : <Navigate replace to="/auth/login" />;
};

const AppRoutes = () => {
  const { stores, accountInfo } = useSelector((state) => state.store);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate replace to="/auth/login" />} />
      <Route exact path="/auth/login" element={<Login />} />
      <Route exact path="/auth/registration" element={<EmailConfirmed />} />
      <Route exact path="/auth/password-update" element={<PasswordUpdate />} />
      <Route exact path="/auth/welcome" element={<Welcome />} />
      <Route exact path="/auth/forget-password" element={<ForgetPassword />} />
      <Route exact path="/auth/new-password" element={<ResetPassword />} />
      <Route exact path="/auth/reset-password" element={<NewPassword />} />
      <Route
        exact
        path="/auth/sign-up"
        element={
          <ProtectedRoute>
            <SignUp />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/onBoarding"
        element={
          stores.length > 0 && accountInfo?.onBoardingWithLayout ? (
            <Layout>
              <ProtectedRoute type="super">
                <OnBoarding />
              </ProtectedRoute>
            </Layout>
          ) : (
            <Logout>
              <ProtectedRoute type="super">
                <OnBoarding />
              </ProtectedRoute>
            </Logout>
          )
        }
      />
      <Route
        exact
        path="/loginppc"
        element={
          <Logout>
            <ProtectedRoute type="super">
              <LoginPpc />
            </ProtectedRoute>
          </Logout>
        }
      />
      <Route
        exact
        path="/login-amazon"
        element={
          <Logout>
            <ProtectedRoute type="super">
              <AmazonLogin />
            </ProtectedRoute>
          </Logout>
        }
      />
      <Route path="/">
        <Route
          path="/dashboard/performance"
          index
          element={
            <ProtectedRoute type="super">
              <Layout>
                <Performance />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/dashboard/information"
          element={
            <ProtectedRoute type="super">
              <Layout>
                <Information />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/dashboard/products"
          element={
            <ProtectedRoute type="super">
              <Layout>
                <Product />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/inventory"
          element={
            <ProtectedRoute type="super">
              <Layout>
                <Inventory />
              </Layout>
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
