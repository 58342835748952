import React, { useState, useEffect } from 'react';
// laibary
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  Link,
  TableRow,
  TableCell,
  Divider,
  IconButton,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// icons
import {
  BsArrowUpShort,
  BsArrowDownShort,
  BsFileEarmarkArrowDown,
  BsStar,
} from 'react-icons/bs';
import { BiChevronRight } from 'react-icons/bi';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
// component
import ProgressBar from '../../components/progress';
import Product from '../../static/images/product.svg';
import Button from '../../components/button/index';
import Table from '../../components/table';
import AppHeader from '../../components/appHeader';
import SalesReport from '../../components/salesReportCharts';
import Pagination from '../../components/pagination';
// constant
import { productPerformanceHeader, periodComparison } from '../../constant';
import { getDashboardData } from '../../redux/slice/dashboardSlice';

const Index = () => {
  const dispatch = useDispatch();
  const {
    summary = {},
    error,
    loading,
  } = useSelector((state) => state.dashboard);
  const { selectedStore = '' } = useSelector((state) => state.store);
  const { adSale = 0, adOrders = 0, adSpend = 0 } = summary?.adSummary || {};
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (selectedStore) {
      dispatch(getDashboardData({ storeId: selectedStore }));
    }
  }, [selectedStore]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const performance = [
    {
      title: 'Unit Sold',
      amount: adOrders,
      value: 36,
    },
    {
      title: 'Gross Revenue',
      amount: `$${adSale.toFixed(2)}`,
      value: 14,
    },
    {
      title: 'Expenses',
      amount: `$${adSpend.toFixed(2)}`,
      value: 36,
    },
    {
      title: 'Net Profit',
      amount: `$${(adSpend - adSale).toFixed(2)}`,
      value: 36,
    },
  ];
  function createData(product, revenue, expenses, profit, margin, roi, unit) {
    return {
      product,
      revenue,
      expenses,
      profit,
      margin,
      roi,
      unit,
    };
  }
  const data = [];
  for (let i = 0; i <= 3; i += 1) {
    data.push(
      createData(
        <Stack direction="row" spacing={1} alignItems="center">
          <BsStar />
          <img src={Product} alt="no-product" />
          <Stack spacing={0.5} maxWidth="250px">
            <Link fontSize="13px" color="#4F46E5">
              Electric Shaver for Men Wet Dry Foil Mens Electric Razor
            </Link>
            <Box fontSize="12px" color="#71717A">
              FBA:sdb8qy7r7eg
            </Box>
          </Stack>
        </Stack>,
        <Stack spacing={0.5}>
          <Box color="#535A6A" fontSize="13px">
            $24,846.14
          </Box>
          <Box color="#667085" fontSize="12px">
            5,445.43 <BsArrowUpShort className="color-success" />{' '}
          </Box>
        </Stack>,
        <Stack spacing={0.5}>
          <Box color="#535A6A" fontSize="13px">
            $24,846.14
          </Box>
          <Box color="#667085" fontSize="12px">
            5,445.43 <BsArrowDownShort className="color-danger" />{' '}
          </Box>
        </Stack>,
        <Stack spacing={0.5}>
          <Box color="#535A6A" fontSize="13px">
            $24,846.14
          </Box>
          <Box color="#667085" fontSize="12px">
            5,445.43 <BsArrowUpShort className="color-success" />{' '}
          </Box>
        </Stack>,
        <Stack spacing={0.5}>
          <Box color="#535A6A" fontSize="13px">
            $24,846.14
          </Box>
          <Box color="#667085" fontSize="12px">
            5,445.43 <BsArrowDownShort className="color-danger" />{' '}
          </Box>
        </Stack>,
        <Stack spacing={0.5}>
          <Box color="#535A6A" fontSize="13px">
            $24,846.14
          </Box>
          <Box color="#667085" fontSize="12px">
            5,445.43 <BsArrowDownShort className="color-danger" />{' '}
          </Box>
        </Stack>,
        <Stack spacing={0.5}>
          <Box color="#535A6A" fontSize="13px">
            $24,846.14
          </Box>
          <Box color="#667085" fontSize="12px">
            5,445.43 <BsArrowDownShort className="color-danger" />{' '}
          </Box>
        </Stack>
      )
    );
  }
  function comparisoncreateData(
    product,
    revenue,
    expenses,
    profit,
    margin,
    roi,
    refund,
    pageView,
    session,
    unit
  ) {
    return {
      product,
      revenue,
      expenses,
      profit,
      margin,
      roi,
      refund,
      pageView,
      session,
      unit,
    };
  }
  const ComparisonData = [];
  for (let i = 0; i <= 3; i += 1) {
    ComparisonData.push(
      comparisoncreateData(
        <Stack spacing={1}>
          <Box color="#535A6A" fontSize="13px">
            Yesterday
          </Box>
          <Box color="#667085" fontSize="12px">
            May 31, 2023 - May 31, 2023
          </Box>
        </Stack>,
        <Box color="#535A6A" fontSize="13px">
          $5,846.84
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          -$2,845.24
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          $2,489.12
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          48%
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          1.305%
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          12
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          202
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          0
        </Box>,
        <Box color="#535A6A" fontSize="13px">
          32,345
        </Box>
      )
    );
  }
  const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
  ];
  const ITEM_HEIGHT = 48;
  return (
    <>
      <AppHeader pathname="Performance" />
      {loading ? (
        <div
          style={{
            height: '70vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            color="inherit"
            sx={{
              width: '30px !important',
              margin: '10px 12px 4px 12px',
              height: '30px !important',
            }}
          />
        </div>
      ) : (
        <>
          <Grid container columnSpacing={2} mt={2}>
            {performance.map((unit, index) => {
              return (
                <Grid item md={3} key={index}>
                  <Box border="1px solid #71717A" borderRadius="10px" p={2}>
                    <Box color="#71717A" fontSize="11px" mb={0.5}>
                      {unit.title}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box fontSize="21px" fontWeight="600">
                        {unit.amount}
                      </Box>
                      <Box
                        fontSize="13px"
                        color={unit.value < 30 ? '#EF4444' : '#22C55E'}
                      >
                        {' '}
                        +{unit.value}{' '}
                        {unit.value < 30 ? (
                          <BsArrowDownShort />
                        ) : (
                          <BsArrowUpShort />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Grid container mt={3} columnSpacing={2}>
            <Grid item md={6}>
              <Box p={2.5} border="1px solid #E4E4E7" borderRadius="10px">
                <Box display="flex" justifyContent="space-between">
                  <Box fontSize="16px" fontWeight="600">
                    Sales Report
                  </Box>
                  <Button
                    variant="outlined"
                    startIcon={
                      <BsFileEarmarkArrowDown sx={{ fontSize: '14px' }} />
                    }
                    text="Export PDF"
                    height="30px"
                  />
                </Box>
                <Box>
                  <SalesReport />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box p={2.5} border="1px solid #E4E4E7" borderRadius="10px">
                <Box fontSize="16px" fontWeight="600">
                  Traffic Sources
                </Box>
                <Stack>
                  <Box my={4}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Box fontSize="13px" color="#414141" fontWeight="600">
                        Organic Sales
                      </Box>
                      <Box fontSize="13px" color="#414141" fontWeight="600">
                        1,43,382
                      </Box>
                    </Box>
                    <ProgressBar value={50} backgroundColor="#4F46E5" />
                  </Box>
                  <Box my={4}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Box fontSize="13px" color="#414141" fontWeight="600">
                        Sponsored Sales{' '}
                      </Box>
                      <Box fontSize="13px" color="#414141" fontWeight="600">
                        87,974
                      </Box>
                    </Box>
                    <ProgressBar value={70} backgroundColor="#FF922E" />
                  </Box>
                  <Box my={4}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Box fontSize="13px" color="#414141" fontWeight="600">
                        Display Sales
                      </Box>
                      <Box fontSize="13px" color="#414141" fontWeight="600">
                        {adSale}
                      </Box>
                    </Box>
                    <ProgressBar
                      value={(2000 / 10000) * 100}
                      backgroundColor="#22C55E"
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Box mt={3} border="1px solid #E4E4E7" borderRadius={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <Stack spacing={1}>
                <Box color="#18181B" fontSize="16px" fontWeight="600">
                  Product Performance
                </Box>
                <Box component="span" color="#71717A" fontSize="13px">
                  Apr 1, 2023 - Apr 30, 2023
                </Box>
              </Stack>
              <Link
                className="pointer"
                sx={{ display: 'flex', alignItems: 'center' }}
                underline="none"
                fontSize="12px"
                color="#4F46E5"
              >
                See All Products
                <Box component="span" mt={0.5}>
                  {' '}
                  <BiChevronRight />
                </Box>
              </Link>
            </Box>
            <Table tableHeader={productPerformanceHeader}>
              {data.map((row) => (
                <TableRow
                  hover
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.product}
                  </TableCell>
                  <TableCell>{row.revenue}</TableCell>
                  <TableCell>{row.expenses}</TableCell>
                  <TableCell>{row.profit}</TableCell>
                  <TableCell>{row.margin}</TableCell>
                  <TableCell>{row.roi}</TableCell>
                  <TableCell>{row.unit}</TableCell>
                </TableRow>
              ))}
            </Table>
            <Divider />
            <Box p={3}>
              <Pagination />
            </Box>
          </Box>
          <Box mt={3} border="1px solid #E4E4E7" borderRadius={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <Stack spacing={1}>
                <Box color="#18181B" fontSize="16px" fontWeight="600">
                  Period Comparison
                </Box>
                <Box component="span" color="#71717A" fontSize="13px">
                  Apr 1, 2023 - Apr 30, 2023
                </Box>
              </Stack>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ color: '#A1A1AA' }} />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === 'Pyxis'}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Table tableHeader={periodComparison}>
              {ComparisonData.map((row) => (
                <TableRow
                  hover
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.product}
                  </TableCell>
                  <TableCell>{row.revenue}</TableCell>
                  <TableCell>{row.expenses}</TableCell>
                  <TableCell>{row.profit}</TableCell>
                  <TableCell>{row.margin}</TableCell>
                  <TableCell>{row.roi}</TableCell>
                  <TableCell>{row.refund}</TableCell>
                  <TableCell>{row.pageView}</TableCell>
                  <TableCell>{row.session}</TableCell>
                  <TableCell>{row.unit}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Box>
        </>
      )}
    </>
  );
};
export default Index;
