import Styled from 'styled-components';
import Select from '@mui/material/Select';

const SelectWrapper = Styled(Select)`
        max-height:52px;
        border-radius:8px!important;
    .MuiOutlinedInput-root{
        background-color:#000;
    }
    .MuiSelect-select{
        padding: 16px 12px;
        font-size: ${({ theme }) => theme.colors.baseFontSize};
        color:${({ theme }) => theme.colors.labelColor};
        
    }
    svg{
              color:#667085;
               font-size: 24px;
               right:10px;
    }
    .MuiMenuItem-root{
        border-bottom: 1px solid black !important;

    }
`;

export default SelectWrapper;
