import * as React from 'react';
import {Rating} from '@mui/material';

const RatingBox =(props) => {
  const {value,onChange}=props;

  return (
    <Rating
      precision={0.5}
      value={value}
      onChange={onChange}
      max={5}
    />
  );
};
export default RatingBox;