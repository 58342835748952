import React from 'react';

const SortingArrows = () => (
  <svg className="sorting-icons" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6621_34189)">
      <path className="sort-ascending" d="M3.49997 9.49997C3.36736 9.49997 3.24019 9.44729 3.14642 9.35352C3.05265 9.25976 2.99997 9.13258 2.99997 8.99997V2.20497L1.85497 3.35497C1.76082 3.44912 1.63312 3.50202 1.49997 3.50202C1.36682 3.50202 1.23912 3.44912 1.14497 3.35497C1.05082 3.26082 0.997925 3.13312 0.997925 2.99997C0.997925 2.86682 1.05082 2.73912 1.14497 2.64497L3.14497 0.644969C3.19145 0.598105 3.24675 0.560908 3.30768 0.535524C3.36861 0.510139 3.43396 0.49707 3.49997 0.49707C3.56598 0.49707 3.63133 0.510139 3.69226 0.535524C3.75319 0.560908 3.80849 0.598105 3.85497 0.644969L5.85497 2.64497C5.94912 2.73912 6.00202 2.86682 6.00202 2.99997C6.00202 3.13312 5.94912 3.26082 5.85497 3.35497C5.76082 3.44912 5.63312 3.50202 5.49997 3.50202C5.36682 3.50202 5.23912 3.44912 5.14497 3.35497L3.99997 2.20497V8.99997C3.99997 9.13258 3.94729 9.25976 3.85352 9.35352C3.75976 9.44729 3.63258 9.49997 3.49997 9.49997Z" fill="currentColor" />
      <path className="sort-descending" d="M8.50205 2.4971C8.36944 2.4971 8.24226 2.54978 8.14849 2.64355C8.05472 2.73732 8.00205 2.86449 8.00205 2.9971V9.7921L6.85705 8.6421C6.76289 8.54795 6.6352 8.49505 6.50205 8.49505C6.36889 8.49505 6.2412 8.54795 6.14705 8.6421C6.05289 8.73625 6 8.86395 6 8.9971C6 9.13025 6.05289 9.25795 6.14705 9.3521L8.14705 11.3521C8.19353 11.399 8.24883 11.4362 8.30976 11.4615C8.37069 11.4869 8.43604 11.5 8.50205 11.5C8.56805 11.5 8.6334 11.4869 8.69433 11.4615C8.75526 11.4362 8.81056 11.399 8.85705 11.3521L10.857 9.3521C10.9512 9.25795 11.0041 9.13025 11.0041 8.9971C11.0041 8.86395 10.9512 8.73625 10.857 8.6421C10.7629 8.54795 10.6352 8.49505 10.502 8.49505C10.3689 8.49505 10.2412 8.54795 10.147 8.6421L9.00205 9.7921V2.9971C9.00205 2.86449 8.94937 2.73732 8.8556 2.64355C8.76183 2.54978 8.63465 2.4971 8.50205 2.4971Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_6621_34189">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
export default SortingArrows;
