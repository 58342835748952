import React from 'react';
import {BsArrowLeftShort,BsArrowRightShort} from 'react-icons/bs';
import { Pagination, Box } from '@mui/material';
import PaginationWrapper from './style';

const Index = (props) => {
  const {
    position,
    width
  } = props;

  return (
    <PaginationWrapper width={width} position={position}>
      <Box
        sx={{
          color: '#5A5F7D',
          padding: '8px 16px',
          maxHeight: '32px',
          paddingRight: 0,
        }}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Box
          sx={{ fontSize: 14 }}
          gap={0.5}
          className="pointer"
          alignItems="center"
          component="span"
        >
          <BsArrowLeftShort className="fs-24" /> Previous
        </Box>
        <Box>
          <Pagination count={10} hidePrevButton hideNextButton />
        </Box>
        <Box
          sx={{ fontSize: 14 }}
          gap={0.5}
          className="pointer"
          alignItems="center"
        >
          Next <BsArrowRightShort className="fs-24" />
        </Box>
      </Box>
    </PaginationWrapper>
  );
};

export default Index;
