import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Radio,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import Button from '../../../components/button';
import { getDefaultProfiles } from '../../../utils/utils';
import {
  addAdsProfile,
  resetState,
  updateAccountInfo,
} from '../../../redux/slice/storeSlice';
import { setAuthToken } from '../../../config/axios-configuration';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'start',
  cursor: 'pointer',
}));

const ConnectAmazon = () => {
  const dispatch = useDispatch();
  const {
    profiles = {},
    store = {},
    loading,
    message,
    error,
    accountInfo,
  } = useSelector((state) => state.store);

  const { melamdo_token } = useSelector((state) => state.auth);

  const [updatedProfiles, setUpdatedProdfiles] = useState(
    getDefaultProfiles(profiles)
  );

  useEffect(() => {
    if (Object.keys(profiles) > 1) {
      setUpdatedProdfiles(getDefaultProfiles(profiles));
    }
  }, [profiles]);

  useEffect(() => {
    if (error && message) {
      toast.error(message);
      dispatch(resetState());
    }
  }, [error, message]);

  const profileClickHandle = (key) => {
    let profilesObj = {};
    Object.keys(updatedProfiles).map((pKey) => {
      profilesObj[pKey] = {
        arr: updatedProfiles[pKey].arr,
        selected: pKey === key,
      };
    });
    setUpdatedProdfiles({ ...profilesObj });
  };

  const profileCountryClickHandle = (key, countryCode) => {
    let profilesObj = {};
    Object.keys(updatedProfiles).map((pKey) => {
      profilesObj[pKey] = {
        ...updatedProfiles[pKey],
        arr: updatedProfiles[pKey].arr.map((profile) => {
          return {
            ...profile,
            selected:
              key === pKey && profile.countryCode === countryCode
                ? !profile.selected
                : profile.selected,
          };
        }),
      };
    });
    setUpdatedProdfiles({ ...profilesObj });
  };

  const addProfileClickHandle = () => {
    const { _id } = store;
    const selectedProfileKey = Object.keys(updatedProfiles).find(
      (key) => updatedProfiles[key].selected
    );
    const marketplaces = updatedProfiles[selectedProfileKey]?.arr
      ?.map((mPlaces) => {
        return mPlaces.selected
          ? mPlaces.accountInfo?.marketplaceStringId
          : null;
      })
      .filter((p) => p);
    setAuthToken(melamdo_token);
    dispatch(
      addAdsProfile({
        storeId: _id,
        sellerId: selectedProfileKey,
        marketplaces: marketplaces,
      })
    );
  };

  return (
    <>
      {store?.adsApi?.profiles?.length > 0 ? (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={6}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              mt={3}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="11px 32px"
            >
              <h3>Amazon Advertising connected</h3>
              <Box
                component="span"
                color="#667085"
                textAlign="left"
                display="flex"
              >
                Next we will connect Selling partner. This will allow Melamdo to
                pull your product details and inventory reports. You will need
                to select your country
              </Box>
              <Box textAlign="right" mt={2}>
                <Button
                  variant="contained"
                  text="Next"
                  width="78px"
                  height="40px"
                  onClick={() => {
                    dispatch(
                      updateAccountInfo({
                        ...accountInfo,
                        activeStep: 2,
                      })
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <Stack spacing={2}>
            {Object.keys(updatedProfiles)?.map((key) => {
              const { arr = [] } = updatedProfiles[key];
              return (
                <Item key={key} onClick={() => profileClickHandle(key)}>
                  <Radio
                    checked={updatedProfiles[key].selected}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  <div>
                    <Typography variant="h6" gutterBottom>
                      {key}
                    </Typography>
                    <div>{arr?.[0]?.accountInfo?.name || ''}</div>
                    <Stack spacing={2} direction="row" sx={{ mt: '20px' }}>
                      {arr?.map((profile, index) => {
                        return (
                          <FormControlLabel
                            key={`${index}-${profile.countryCode}`}
                            control={
                              <Checkbox
                                checked={profile.selected}
                                onChange={() =>
                                  profileCountryClickHandle(
                                    key,
                                    profile.countryCode
                                  )
                                }
                                sx={{ padding: '0px 10px 8px 0px' }}
                              />
                            }
                            label={
                              <img
                                src={`https://www.countryflagicons.com/FLAT/32/${profile.countryCode}.png`}
                                alt={profile.countryCode}
                              />
                            }
                          />
                        );
                      })}
                    </Stack>
                  </div>
                </Item>
              );
            })}
          </Stack>
          <Box textAlign="right" mt={2}>
            <Button
              variant="contained"
              text={
                loading ? (
                  <div>
                    <CircularProgress
                      color="inherit"
                      sx={{
                        width: '30px !important',
                        margin: '10px 12px 4px 12px',
                        height: '30px !important',
                      }}
                    />
                  </div>
                ) : (
                  'Next'
                )
              }
              width="78px"
              height="40px"
              onClick={addProfileClickHandle}
              disabled={
                !Object.keys(updatedProfiles).some((key) => {
                  return (
                    updatedProfiles[key]?.arr?.some(
                      (profile) => profile.selected
                    ) && updatedProfiles[key].selected
                  );
                })
              }
            />
          </Box>
        </div>
      )}
    </>
  );
};
export default ConnectAmazon;
