import Styled from 'styled-components';

const TableWrapper = Styled.div`
    height: ${(props) => `calc(100vh - ${props.height || ''})`};
    max-height: ${(props) => props.maxheight || ''};
    overflow:auto ;
    &.order-workflow-table{
        min-height:331px;
    }
    .MuiTableHead-root {
        background-color:#EAECF0;
        .MuiTableRow-root  {
            .MuiTableCell-root {
                padding:12px 12px;
                color:#52525B;
                font-size:13px;
                font-weight:400;
                line-height:${({ theme }) => theme.colors.baseFontSizeXs};
                background-color:${({ theme }) => theme.colors.background};
                white-space:nowrap;
                     &:first-child{
                    position:${(props) => (props.fixed ? 'sticky' : '')};
                    left:${(props) => (props.fixed ? '0' : '')};
                    background-color:${(props) => (props.fixed ? '#F8F8F8' : '')};
                    z-index:9;
               
            }
                  &:last-child{
                    position:${(props) => (props.fixed ? 'sticky' : '')};
                    right:${(props) => (props.fixed ? '0' : '')};
                    width:${(props) => (props.fixed ? '100px' : '')};
            }
            }
            .MuiTableSortLabel-root{
                .sorting-icons{
                    margin-left:8px;
                   .sort-ascending,.sort-descending{
                    color:${({ theme }) => theme.colors.labelColor};
                   }
                }
                &.sort-descending{
                    .sorting-icons{
                        .sort-descending{
                            color:${({ theme }) => theme.colors.primaryColor};
                        }
                    }
                }
                &.sort-ascending{
                    .sorting-icons{
                        .sort-ascending{
                            color:${({ theme }) => theme.colors.primaryColor};
                        }
                    }
                }
            }
        }
    }
    .MuiTableBody-root {
        .MuiTableRow-root {
               vertical-align:${(props) => (props.alignCenter ? 'middle' : 'top')};
            &.selected{
                   background-color:${({ theme }) => theme.colors.primaryColor}05;
                    .MuiTableCell-root{
                        border-bottom:1px solid ${({ theme }) => theme.colors.primaryColor}
            }
            }
            .MuiTableCell-root{
                font-size:${({ theme }) => theme.colors.baseFontSize};
                color:${({ theme }) => theme.colors.tableBody};
                padding:${(props) => (props.bodyPadding ? `${props.bodyPadding}` : '12px 12px')};
                line-height: 16px;
              &:first-child{
                    position:${(props) => (props.fixed ? 'sticky' : '')};
                    left:${(props) => (props.fixed ? '0' : '')};
                    background-color:${(props) => (props.fixed ? '#fff' : '')};
                           z-index:1;
            }

                &:last-child{
                    position:${(props) => (props.fixed ? 'sticky' : '')};
                    right:${(props) => (props.fixed ? '0' : '')};
                    width:${(props) => (props.fixed ? '100px' : '')};
                    background-color:${(props) => (props.fixed ? '#fff' : '')};
            }
                &:last-child th{
                border-bottom:1px solid #ccc;
            }
                 &:last-child td{
                border-bottom:1px solid #ccc;
            }


        }


    }
}
`;
export default TableWrapper;
