
import styled from 'styled-components';

const SideBarWrapper = styled.div`
    min-width:320px;
    height: calc(100vh - 102px);
    background-color: #F5F5F5;
    padding:32px; 
    .MuiButtonBase-root{
        margin-bottom:16px;
        &:hover{
            background-color: #0948A7;  
            color:#fff;  
            svg{
                color:#fff;   
            }
        }
        &.Mui-selected{
            background-color: #0948A7;
            color:#fff;  
            svg{
                color:#fff;   
            }
            &:hover{
                background-color: #0948A7;  
            }
        }

        }
`;

export default SideBarWrapper;