import React from 'react';
import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// styles
import SearchWrapper from './style';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 8,
  backgroundColor:'#fff',
  border: '1px solid #0000003b',
  '&:hover': {
    backgroundColor:'#fff',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(() => ({
  padding: '0 8px',
  height: '100%',
  position: 'absolute',
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#667085',
  zIndex: 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '0px 28px 0px 0px',
    fontSize: 16,
    color: '#272B41',
    borderRadius: 8,
    // vertical padding + font size from searchIcon
    paddingLeft: 32,
    transition: theme.transitions.create('width'),
    width: '100%',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#979797',
      opacity: 1,
    },
  },
}));

export default function SearchAppBar(props) {
  const {
    placeholder,
    value,
    onChange,
    defaultValue,
    sx,
    scanStyle,
    width,
    label,
    onClick,
    name,
    large,
    fontSize,
    height,
    iconFontSize
  } = props;

  return (
    <SearchWrapper sx={sx} scanStyle={scanStyle} height={height} large={large}>
      {label && <label>{label}</label>}
      <Search>
        <SearchIconWrapper>
          <SearchIcon sx={{ fontSize: iconFontSize ? iconFontSize : 24 , color: '#667085' }} className="pointer" onClick={onClick} />
        </SearchIconWrapper>
        <StyledInputBase
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          value={value}
          onChange={onChange}
          sx={{ width: width || '100%', fontSize: fontSize || '13px' }}
        />
      </Search>
    </SearchWrapper>
  );
}
