import React, { useEffect, useState } from 'react';

import { Grid, Box, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import SignUpLogo from '../../static/images/signUp.svg';
import Input from '../../components/inputs/input/index';
import SearchInput from '../../components/searchInput';
import Select from '../../components/select';
import CheckBox from '../../components/checkbox';
import Button from '../../components/button';
import { signUp, resetState } from '../../redux/slice/authSlice';
import { setAuthToken } from '../../config/axios-configuration';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, melamdo_token, pre_signup_melamdo_token } = useSelector(
    (state) => state.auth
  );

  const [isDisabled, setIsDisabled] = useState(true);
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    company: '',
    country: '',
    phone: '',
    password: '',
    referralCode: '',
    adSpend: null,
    isAgreeTerms: false,
    isSendPromotions: false,
  });

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (melamdo_token) {
      navigate('/onBoarding');
    }
  }, [melamdo_token]);

  useEffect(() => {
    const { firstName, lastName, company, country, phone, password, adSpend } =
      inputValues;
    setIsDisabled(
      !(
        firstName &&
        lastName &&
        company &&
        country &&
        phone &&
        password &&
        adSpend
      )
    );
  }, [inputValues]);

  useEffect(() => {
    if (error && message) {
      toast.error(message);
    }
  }, [error, message]);

  const inputChangeHandle = (e) => {
    const { name, value, checked } = e.target || {};
    setInputValues({
      ...inputValues,
      [name]:
        name === 'isAgreeTerms' || name === 'isSendPromotions'
          ? checked
          : value,
    });
  };

  const signUpClickHandle = () => {
    setAuthToken(pre_signup_melamdo_token);
    dispatch(signUp({ ...inputValues }));
  };

  const signUpList = [
    'Verify your company information',
    'Integrate your Amazon account',
    'Get personlized support from our dedicated team',
  ];
  return (
    <Grid container>
      <Grid item md={4}>
        <Box
          bgcolor="#EEF2FF"
          textAlign="center"
          height="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img src={SignUpLogo} alt="no-logo" />
          <Box textAlign="center" px={8}>
            <Box component="h3" fontFamily="'Inter', sans-serif">
              Welcome to Melamdo
            </Box>
            <Box
              component="p"
              mb={4}
              color="#667085"
              fontFamily="'Inter', sans-serif"
              fontSize="16px"
            >
              Unlock the full potential of your account and access expert
              insights from our professionals.
            </Box>
            <ul>
              {signUpList.map((list, index) => (
                <Box mb={4} display="flex" fon component="li" key={index}>
                  <Box
                    width="20px"
                    height="20px"
                    borderRadius="50%"
                    bgcolor="#0948A7"
                    color="#fff"
                    fontSize="12px"
                    mr={2}
                  >
                    {index + 1}
                  </Box>
                  <Box color="#667085" textAlign="left">
                    {list}
                  </Box>
                </Box>
              ))}
            </ul>
          </Box>
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box bgcolor="#fff" borderRadius={2} mt={124 / 8} p={3} mx={5}>
          <Grid container columnSpacing={3}>
            <Grid item md={6}>
              <Input
                label="First Name"
                width="100%"
                placeholder="First Name"
                height="52px"
                borderRadius="8px"
                name="firstName"
                onChange={inputChangeHandle}
                value={inputValues.firstName}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Last Name"
                width="100%"
                placeholder="Last Name"
                height="52px"
                borderRadius="8px"
                name="lastName"
                onChange={inputChangeHandle}
                value={inputValues.lastName}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Company Name"
                width="100%"
                placeholder="My  Company"
                height="52px"
                borderRadius="8px"
                name="company"
                onChange={inputChangeHandle}
                value={inputValues.company}
              />
            </Grid>
            <Grid item md={6}>
              <SearchInput
                label="Where are you located?"
                name="country"
                onChange={inputChangeHandle}
                value={inputValues.country}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Phone Number"
                width="100%"
                height="52px"
                borderRadius="8px"
                name="phone"
                onChange={inputChangeHandle}
                value={inputValues.phone}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Have and offer or referral code?"
                placeholder="Enter code (optional)"
                width="100%"
                height="52px"
                borderRadius="8px"
                name="referralCode"
                onChange={inputChangeHandle}
                value={inputValues.referralCode}
              />
            </Grid>

            <Grid item md={6}>
              <Select
                label="Average monthly Ad spend"
                placeholder="-Select Ad Spend"
                width="100%"
                name="adSpend"
                menuItem={[1000, 2000, 3000, 4000]}
                handleChange={inputChangeHandle}
                value={inputValues.adSpend}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Password"
                placeholder="Create password"
                width="100%"
                height="52px"
                borderRadius="8px"
                name="password"
                onChange={inputChangeHandle}
                value={inputValues.password}
                type="password"
              />
            </Grid>
          </Grid>
          <Box>
            <CheckBox
              label="I agree to the Terms of Services and Privacy Policy."
              marginBottom="16px"
              name="isAgreeTerms"
              onChange={inputChangeHandle}
              checked={inputValues.isAgreeTerms}
            />
            <CheckBox
              label="I agree to receive product updates, offers and promotions from Melamdo."
              name="isSendPromotions"
              onChange={inputChangeHandle}
              checked={inputValues.isSendPromotions}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              variant="contained"
              width="219px"
              borderRadius="9px"
              text={
                loading ? (
                  <div>
                    <CircularProgress
                      color="inherit"
                      sx={{
                        width: '30px !important',
                        margin: '10px 12px 4px 12px',
                        height: '30px !important',
                      }}
                    />
                  </div>
                ) : (
                  'Create Account'
                )
              }
              onClick={signUpClickHandle}
              disabled={isDisabled}
            />
            <Box color="#71717A">
              Aready have an account?
              <Link
                className="ml-2 pointer"
                underline="none"
                href="/auth/login"
                sx={{ color: '#0948A7', paddingLeft: '8px' }}
              >
                Sign In
              </Link>{' '}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default SignUp;
