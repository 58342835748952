import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({theme,backgroundColor}) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:backgroundColor,
  },
}));


export default function CustomizedProgressBars(props) {
  const {value,backgroundColor} =props;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate"  value={value} backgroundColor={backgroundColor}  />
    </Box>
  );
}