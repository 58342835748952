import React from 'react';

import { Box } from '@mui/material';
import Header from '../components/header/index';
import Sidebar from '../components/sidebar';
import LayoutWrapper from './style';

const Index = ({ children }) => {

  const pathName = window.location.pathname.split('/')[1];

  return (
    <LayoutWrapper>
      <Header title={pathName === '' ? 'Performance' : pathName} />
      <Box display="flex">
        <Sidebar />
        <Box
          p={3}
          width="100%"
          sx={{ height: 'calc(100vh - 90px)' }}
          overflow="auto"
        >
          {children}
        </Box>
      </Box>
    </LayoutWrapper>
  );
};

export default Index;
