import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body{
        font-size:${({ theme }) => theme.colors.baseFontSize};
        font-weight:400;
        line-height:normal;
        background-color:${({ theme }) => theme.colors.bodyColor};
    }
    h1,h2,h3,h4,h5,h6{
         font-weight:600;
         color:${({ theme }) => theme.colors.secondaryColor}
    }
    a{
        color:${({ theme }) => theme.colors.primaryColor};
        text-decoration:none;
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
      }
    .title{
        font-size:${({ theme }) => theme.colors.baseFontSizeXl};
        line-height:44px;
        margin-bottom:32px;
    }
    h1{
        font-size:${({ theme }) => theme.colors.baseFontSizeXl };
    }
    h2{
        font-size:${({ theme }) => theme.colors.baseFontSizeLg};
    }
    h3{
        font-size:${({ theme }) => theme.colors.baseFontSizeSm}
    }
    h5{
        font-size:${({ theme }) => theme.colors.baseFontSizeLg}
    }
    label{
        font-size:${({ theme }) => theme.colors.baseFontSize};
        font-weight:500;
        margin-bottom:3px;
        display: inline-block;
    }
    .pointer{
        cursor: pointer;
    }
    .m-0{
        margin:0;
    }
    .color-success{
        color:${({ theme }) => theme.colors.accentColorSuccess};
    }
    .color-danger{
        color:${({ theme }) => theme.colors.dangerColor};  
    }
    .fs-24{
        font-size:${({ theme }) => theme.colors.baseFontSizeSm}
    }
    .fs-13{
        font-size:13px; 
    }
    .text-ellipse{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display:inline-block;
        max-width: 90px;

    }
    .color-secondary{
        color:${({ theme }) => theme.colors.secondaryColor};  
    }
`;
export default GlobalStyles;
