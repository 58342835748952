import USA from '../static/images/usa.svg';
import EU from '../static/images/eu.svg';
import JP from '../static/images/jp.svg';

export const REGION_ITEMS = [
  { value: 'eu', label: 'European Union' },
  { value: 'na', label: 'North America' },
  { value: 'fe', label: 'Far East' },
];

export const ADVERTISING_CLIENT_ID =
  'amzn1.application-oa2-client.9941c81b39a14c9c9caea895eed1b094';

export const ADVERTISING_REDIRECT_URI =  process.env.NODE_ENV !== 'production' ? 'http://localhost:3000/loginppc': 'https://app.melamdo.com/loginppc';

export const APPLICATION_ID =
  'amzn1.sp.solution.e092d569-b87d-43b8-b18d-fdeab12ef6b5';
export const REDIRECT_URI = 'https://app.melamdo.com/login-amazon';

export const REGIONS = {
  na: USA,
  eu: EU,
  fe: JP
};