import Styled from 'styled-components';

const CheckBoxWrapper = Styled.span`
margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '20px')};

display:block ;
.MuiTypography-root  {
    color: #333333;
    font-size: 16px;
           margin-left: 4px;
           letter-spacing: 0px;
           font-family:'Inter', sans-serif;

}
.header-checkbox{
    .MuiButtonBase-root {
        padding:0
    }
    .MuiFormControlLabel-root {
            margin: 0;
    }
}
.body-checkbox{
        .MuiButtonBase-root {
        padding:0
    }
    .MuiFormControlLabel-root {
            margin: 0;
    }
}
.MuiButtonBase-root {
 padding: ${(props) => (props.padding ? props.padding : '')};
}
`;

export default CheckBoxWrapper;
