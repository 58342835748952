import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Box,
  Stack,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from '@mui/material';
import {
  BsHouse,
  BsFolder,
  BsFileBarGraph,
  BsBarChart,
  BsGear,
} from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';
import { MdPending } from 'react-icons/md';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector, useDispatch } from 'react-redux';

// component
import SideBarWrapper from './style';
import StoreFlyout from './StoreFlyout';
import { REGIONS, REGION_ITEMS } from '../../utils/constants';
import {
  updateAccountInfo,
  getAdsStoreProfiles,
  updateStore,
} from '../../redux/slice/storeSlice';
import { getDashboardData } from '../../redux/slice/dashboardSlice';

// images
import Amazon from '../../static/images/amazon-sidebar.svg';

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeFlyoutRef = useRef(null);

  const { stores, selectedStore, accountInfo } = useSelector(
    (state) => state.store
  );

  const [selectedItem, setselectedItem] = useState('');
  const [addAccount, setAddAccount] = useState(false);

  const handleListItemClick = (event, index) => {
    setselectedItem(index);
  };
  useEffect(() => {
    const storedSelectedItem = localStorage.getItem('selectedItem');
    if (storedSelectedItem) {
      setselectedItem(storedSelectedItem);
    }
  }, []);

  useEffect(() => {
    if (selectedStore) {
      dispatch(getDashboardData({ storeId: selectedStore }));
    }
  }, [selectedStore]);

  useEffect(() => {
    if (selectedItem) {
      localStorage.setItem('selectedItem', selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (addAccount && storeFlyoutRef) {
      storeFlyoutRef.current?.focus();
    }
  }, [addAccount]);

  const menu = [
    {
      icon: <BsHouse />,
      title: 'Dashboard',
      path: '/dashboard/performance',
    },
    {
      icon: <BsFolder />,
      title: 'Products',
      path: '/dashboard/products',
    },
    {
      icon: <BsBarChart />,
      title: 'Inventory',
      path: '/dashboard/inventory',
    },
    {
      icon: <BsFileBarGraph />,
      title: 'Information',
      path: '/dashboard/information',
    },
  ];

  const bottomMenu = [
    {
      icon: <BsGear />,
      title: 'Setting',
      path: '/setting',
    },
    {
      icon: <FiLogOut />,
      title: 'Logout',
      path: '',
    },
  ];

  const filteredStore =
    stores.find((store) => store._id === selectedStore) || stores[0];
  return (
    <SideBarWrapper>
      <Stack justifyContent="space-between" height="100%">
        <Box>
          <Box
            fontSize="16px"
            color="#A1A1AA"
            fontFamily="'Plus Jakarta Sans',sans-seris"
          >
            Account
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={3}
            position="relative"
          >
            <Box display="flex" gap={2}>
              {filteredStore?.spApi?.isConnected ? (
                <img src={Amazon} alt="no-store" />
              ) : (
                <MdPending
                  style={{ height: '42px', width: '42px', cursor: 'pointer' }}
                  onClick={async () => {
                    if (filteredStore?.spApi?.marketplaces?.length > 0) {
                      dispatch(
                        updateAccountInfo({
                          ...accountInfo,
                          activeStep: 3,
                          name: filteredStore.name,
                          region: filteredStore.region,
                        })
                      );
                    } else if (filteredStore?.adsApi?.profiles?.length > 0) {
                      dispatch(
                        updateAccountInfo({
                          ...accountInfo,
                          activeStep: 2,
                          name: filteredStore.name,
                          region: filteredStore.region,
                        })
                      );
                    } else if (filteredStore?.adsApi?.isConnected) {
                      await dispatch(
                        getAdsStoreProfiles({ _id: filteredStore._id })
                      );
                      dispatch(
                        updateAccountInfo({
                          ...accountInfo,
                          authCode: '',
                          activeStep: 0,
                          name: filteredStore.name,
                          region: filteredStore.region,
                        })
                      );
                      dispatch(updateStore(filteredStore));
                    } else {
                      dispatch(
                        updateAccountInfo({
                          ...accountInfo,
                          activeStep: 4,
                        })
                      );
                    }
                    navigate('/onBoarding');
                  }}
                />
              )}
              <Stack spacing={1}>
                <Box
                  color="#18181B"
                  fontSize="14px"
                  fontWeight="bold"
                  fontFamily="'Plus Jakarta Sans',sans-seris"
                >
                  {filteredStore?.name}
                </Box>
                <Box display="flex" gap={1}>
                  <img
                    src={REGIONS[filteredStore?.region]}
                    alt="no-country"
                    height="18px"
                  />
                  <Box fontSize="10px" color="#A1A1AA" component="span">
                    {
                      REGION_ITEMS.find(
                        (re) => filteredStore?.region === re.value
                      ).label
                    }
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box
              ref={storeFlyoutRef}
              onBlur={() => setAddAccount(false)}
              tabIndex="0"
            >
              <ChevronRightIcon
                sx={{
                  transform: addAccount ? 'rotate(180deg)' : '',
                  color: '#64748B',
                }}
                className="pointer"
                onClick={() => setAddAccount(!addAccount)}
              />
              {addAccount && (
                <StoreFlyout onBlur={() => setAddAccount(false)} />
              )}
            </Box>
          </Box>
          <Box fontSize="16px" color="#A1A1AA">
            Menu
          </Box>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {menu.map((list, index) => {
              return (
                <ListItemButton
                  key={index}
                  selected={selectedItem === list.path}
                  onClick={() => {
                    navigate(list.path);
                    setselectedItem(list.path);
                  }}
                >
                  <ListItemIcon>{list.icon}</ListItemIcon>
                  <ListItemText primary={list.title} />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
        <Box>
          {bottomMenu.map((list, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={(event) => {
                  handleListItemClick(event, index);
                  if (index === 1) {
                    localStorage.clear();
                    window.open('/', '_self');
                  }
                }}
              >
                <ListItemIcon>{list.icon}</ListItemIcon>
                <ListItemText primary={list.title} />
              </ListItemButton>
            );
          })}
        </Box>
      </Stack>
    </SideBarWrapper>
  );
};

export default Index;
