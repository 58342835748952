import React, { useState, useEffect } from 'react';

import { Box, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '../../../components/button';
import Logo from '../../../static/images/logo.svg';
import Input from '../../../components/inputs/input';
import { forgotPassword, resetState } from '../../../redux/slice/authSlice';

const EmailConfirmed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, success } = useSelector(
    (state) => state.auth
  );

  const [email, setEmail] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (error && message) {
      toast.error(message);
    }
  }, [error, message]);

  useEffect(() => {
    success && navigate(`/auth/new-password?email=${email}`);
  }, [navigate, success]);

  return (
    <>
      <Box textAlign="center" pt={60 / 8}>
        <img src={Logo} alt="no-logo" />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={4}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              mt={74 / 8}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="24px"
            >
              <Stack alignItems="flex-start">
                <Box
                  component="h3"
                  mb={2.2}
                  className="m-0"
                  fontFamily="'Inter', sans-serif"
                >
                  Forgot password
                </Box>
                <Box
                  mb={2}
                  fontSize="14px"
                  color="#667085"
                  fontFamily="'Inter', sans-serif"
                >
                  Enter your email and we will send a link to rest your
                  password.
                </Box>
              </Stack>
              <Box textAlign="left">
                <Input
                  label="Email"
                  placeholder="example@email.com"
                  width="100%"
                  borderRadius="8px"
                  onChange={(e) => {
                    const { value } = e?.target || {};
                    setEmail(value);
                  }}
                  type="email"
                  value={email}
                />
              </Box>
              <Box
                display="flex"
                gap={2}
                pt={2}
                direction="row"
                justifyContent="end"
              >
                <Button
                  height="40px"
                  text="Back"
                  onClick={() => navigate('/')}
                />
                <Button
                  variant="contained"
                  height="40px"
                  text={
                    loading ? (
                      <div>
                        <CircularProgress
                          color="inherit"
                          sx={{
                            width: '30px !important',
                            margin: '10px 12px 4px 12px',
                            height: '30px !important',
                          }}
                        />
                      </div>
                    ) : (
                      'Submit'
                    )
                  }
                  disabled={!email}
                  onClick={() => {
                    dispatch(forgotPassword({ email }));
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default EmailConfirmed;
