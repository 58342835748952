import Styled from 'styled-components';
import Button from '@mui/material/Button';

const ButtonWrapper = Styled(Button)`
  &.MuiButtonBase-root {
    box-shadow:none;
    height:${(props) => (props.height ? `${props.height}` : '64px')};
    font-size:${(props,theme) => (props.fontSize ? props.fontSize : theme?.colors.baseFontSize)};
    text-transform: capitalize;
    font-family: 'Plus Jakarta Sans', sans-serif;
    background: ${(props) => (props.variant === 'contained' ? props.theme.colors.primaryColor
    : props.variant === 'text' ?  '' :
      props.variant === 'outlined' ?  '' :  '#F5F5F5')};
    min-width: ${(props) => `${props.width || ''}`};
    max-width: ${(props) => `${props.width || ''}`};
    color: ${(props) => (props.variant === 'contained' ? props.theme.colors.onPrimary : props.variant === 'text' ? '#0948A7' :'#64748B')};
    font-weight:  ${({ theme }) => theme['bold-weight']};
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}` : '4px')};
    border-color:${(props) => (props.variant === 'text' ?  '' :  props.variant === 'outlined' ? '#D4D4D8' :props.theme.colors.primaryColor)};
    /* border:${(props) => (props.variant === 'text' ? '' : `1px solid ${props.theme.colors.primaryColor}`)};   */
    padding:4px 16px 4px 14px;
    letter-spacing:0px;
    svg{
      font-size:12px;
    }
    &.MuiButton-textError{
         color: ${({ theme }) => theme.colors.dangerColor};
         border-color: ${({ theme }) => theme.colors.dangerColor};
          .MuiButton-startIcon{
            margin-right:6px;
             span{
               &::before{
                   color: ${({ theme }) => theme.colors.dangerColor};
               }
             }
           }
    }
    &.Mui-disabled{
           color: ${({ theme }) => theme.colors.labelColor};
            background-color:${({ theme }) => theme.colors.primaryColor};
            opacity:0.3;
           .MuiButton-startIcon{
             span{
               &::before{
                   color: ${({ theme }) => theme.colors.labelColor};
               }
             }
           }
    }
    .MuiButton-startIcon {
      font-size:16px;
      margin-left:0;
      span,.MuiSvgIcon-root{
       font-size:16px;
       &::before{
      color: ${(props) => (props.variant === 'contained' ? props.theme.colors.onPrimary : props.theme.colors.primaryColor)};
       }

      }
    }
    &:hover{
      box-shadow:none;
    }
    &.btn-large{
      padding:0 59px;
    }
    &.btn-small{
          font-size: ${({ theme }) => theme.colors.baseFontSizeXs};
    }
    &.icon-button{
      .MuiButton-startIcon {
        margin:0;
      }
        padding: 0px 6px;
        min-width: 32px
    }

  }

`;

export default ButtonWrapper;
