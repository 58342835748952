import React, { useEffect } from 'react';

import { Box, Grid, Stack, Link } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../../static/images/logo.svg';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const email = new URLSearchParams(search)?.get('email') || '';

  useEffect(() => {
    if (!email) {
      navigate('/auth/forget-password');
    }
  }, []);

  return (
    <>
      <Box textAlign="center" pt={60 / 8}>
        <img src={Logo} alt="no-logo" />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={4}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              textAlign="left"
              mt={74 / 8}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="11px 32px"
            >
              <Box
                width="64px"
                height="64px"
                bgcolor="#F4F9FF"
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: '50px' }}
              >
                <Box
                  width="50px"
                  height="50px"
                  bgcolor="#DEEBFD"
                  sx={{ borderRadius: '50px' }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmailOutlinedIcon sx={{ color: '#0948A7' }} />
                </Box>
              </Box>
              <Stack alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="h3"
                  mb={2.2}
                  className="m-0"
                  fontFamily="'Inter', sans-serif"
                >
                  Check your email
                </Box>
                <Box
                  mb={2}
                  fontSize="14px"
                  color="#667085"
                  fontFamily="'Inter', sans-serif"
                >
                  An email has been sent to <b>{email}</b> and includes a link
                  to reset your password.
                </Box>
              </Stack>
              <Link
                className="ml-2 pointer"
                underline="none"
                href="/auth/login"
                sx={{
                  color: '#0948A7',
                  display: 'flex',
                  justifyContent: 'right',
                  margin: '10px 0px 10px 0px',
                }}
              >
                Sign In
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ResetPassword;
