import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateAccountInfo } from '../../redux/slice/storeSlice';

function LoginPpc() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountInfo } = useSelector((state) => state.store);

  const authCode = new URLSearchParams(search)?.get('code') || '';

  useEffect(() => {
    if (authCode) {
      dispatch(
        updateAccountInfo({
          ...accountInfo,
          authCode,
        })
      );
      navigate('/onBoarding');
    }
  }, [authCode]);

  return null;
}

export default LoginPpc;
