import Styled from 'styled-components';
import { Box } from '@mui/material';

const SearchWrapper = Styled(Box)`
 .MuiInputBase-input{
    height:${(props) => (props.height ? `${props.height}` : '52px')};
    font-size:${(props) => (props.large ? '16px' : '')};
    font-weight:${(props) => (props.large ? '700' : '')};
    border-radius:8px;
 }
`;

export default SearchWrapper;
