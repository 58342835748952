import React,{useState} from 'react';
import {Box,Stack,Link} from '@mui/material';
// icons 
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {BsStar } from 'react-icons/bs';
// component
import AppHeader from '../../../components/appHeader';
import Button from '../../../components/button';
import Tabs from '../../../components/tabs';
import Parent from '../../dashboard/products/component/parent';
// images
import InventoryImg from '../../../static/images/inventory.svg';

const Inventory =()=>{
  const [tabValue, setTabValue] = useState(0);
  const tabs =[
    {title: 'Parent',component: <Parent /> },
    {title: 'Child',component: <Parent /> },
    {title: 'Sku',component: <Parent /> },
  ];
  const handleTabValue = (newValue) => {
    setTabValue(newValue);
  };
  function createData(
    product,
    revenue,
    expenses,
    profit,
    margin,
    roi,
    unit
  ) {
    return {
      product,
      revenue,
      expenses,
      profit,
      margin,
      roi,
      unit
    };
  }
  const data = [];
  for (let i = 0; i <= 10; i += 1) {
    data.push(
      createData(
        <Stack direction="row" spacing={1} alignItems="center">
          <BsStar />
          <img src={InventoryImg} alt="no-product" />
          <Stack spacing={0.5} maxWidth="250px">
            <Link
              selectedItem color="#0948A7">Electric Shaver for Men Wet Dry Foil Mens Electric Razor</Link>
            <Box fontSize="12px" color="#71717A" display="flex" alignItems="center">
                        FBA:sdb8qy7r7eg
              <ContentCopyOutlinedIcon className="pointer" sx={{color:'#000',fontSize:'12px',marginLeft:'8px',marginRight:'8px'}} />
                        GR-PastalRed USA
            </Box>
          </Stack>
        </Stack>,
        <Box color="#535A6A" selectedItem>$39.85</Box>,
        <Box color="#535A6A" selectedItem>$14.28</Box>,
        <Box color="#535A6A" selectedItem>255</Box>,
        <Box color="#535A6A" selectedItem>0</Box>,
        <Box color="#535A6A" selectedItem>33</Box>,
        <Box color="#535A6A" selectedItem>200</Box>
      )
    );
  }
  return(
    <>
      <AppHeader pathname="Products" />
      <Box  border="1px solid #E4E4E7" mt={3} borderRadius="10px">
        <Box p={3} display="flex" justifyContent="space-between">
          <Box fontSize="16px" fontWeight="600" color="#18181B">My Products</Box>
          <Button startIcon={<FileUploadOutlinedIcon />} text="Export Data" height="36px" />
        </Box>
        <Tabs
          tabs={tabs}
          value={tabValue}
          onTabChange={(newValue) => handleTabValue(newValue)}
          className="purchasing-tabs"
        />
      </Box>
    </>

  );
};
export default Inventory;