import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBaseUrl, setAuthToken } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const connectADSAPIStore = createAsyncThunk(
  '/store',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/store', data);
      const { store } = response.data || {};
      const getProfilesResponse = await axios.get(
        `/store/ad-profiles?storeId=${store._id}`
      );

      if (Object.keys(getProfilesResponse.data?.profiles || {}).length === 0) {
        return rejectWithValue({
          error: { error: 'No Profile found in this store' },
        });
      }
      await dispatch(getStores());
      return {
        store: store,
        profiles: getProfilesResponse.data?.profiles,
      };
    } catch (err) {
      if (err.response && err.response.data) {
        const { status } = err.response;
        return rejectWithValue({
          error:
            status === 401
              ? { error: 'Unauthorized' }
              : { error: err?.response?.data?.message },
          message:
            status === 401
              ? { error: 'Unauthorized' }
              : { error: err?.response?.data?.message },
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const getAdsStoreProfiles = createAsyncThunk(
  '/store/ad-profiles',
  async (data, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token || data.token;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const response = await axios.get(
          `/store/ad-profiles?storeId=${data._id}`
        );
        return response.data;
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

export const addAdsProfile = createAsyncThunk(
  '/store/add-ads-profiles',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/store/add-ads-profiles', data);
      await dispatch(getStores());
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        const { status } = err.response;
        return rejectWithValue({
          error:
            status === 401
              ? { error: 'Unauthorized' }
              : { error: err?.response?.data?.message },
          message:
            status === 401
              ? { error: 'Unauthorized' }
              : { error: err?.response?.data?.message },
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const connectSPAAPIStore = createAsyncThunk(
  '/store',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/store', data);
      await dispatch(getStores());
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        const { status } = err.response;
        return rejectWithValue({
          error:
            status === 401
              ? { error: 'Unauthorized' }
              : { error: err?.response?.data?.message },
          message:
            status === 401
              ? { error: 'Unauthorized' }
              : { error: err?.response?.data?.message },
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const getStore = createAsyncThunk(
  '/store/store',
  async (data, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const response = await axios.get(
          `/store/store?storeId=${data.storeId}`
        );
        return response.data;
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

export const getStores = createAsyncThunk(
  '/store/stores',
  async (data, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token || data.token;
      const selectedStore = getState()?.store?.selectedStore;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const response = await axios.get('/store/stores');
        const stores = response?.data?.stores;
        return {
          stores: stores || [],
          selectedStore: selectedStore || (stores?.length > 0 && stores[0]._id),
        };
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

const store = createSlice({
  name: 'store',
  initialState: {
    success: false,
    message: '',
    loading: false,
    error: '',
    accountInfo: {
      name: '',
      region: '',
      storeType: 'ads',
      authCode: '',
      sellerId: '',
      activeStep: 0,
      randomState: `${Math.random()}${new Date().getSeconds()}`,
      state: '',
      dashboard: false,
      onBoardingWithLayout: false,
    },
    selectedStore: '',
    store: {},
    stores: [],
    profiles: [],
  },
  reducers: {
    updateAccountInfo: (state, payload) => {
      return {
        ...state,
        accountInfo: payload.payload,
      };
    },
    updateStore: (state, payload) => {
      return {
        ...state,
        store: payload.payload,
      };
    },
    resetUpdateAccountInfo: (state, payload) => {
      return {
        ...state,
        accountInfo: payload.payload,
        store: {},
      };
    },
    updateSelectedStore: (state, payload) => {
      return {
        ...state,
        selectedStore: payload?.payload?.selectedStore || '',
        // store: payload?.payload?.store
      };
    },
    resetState: (state) => ({
      ...state,
      success: false,
      message: '',
      loading: false,
      error: '',
    }),
  },
  extraReducers: {
    [connectADSAPIStore.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [connectADSAPIStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      store: action.payload.store,
      profiles: action.payload.profiles,
      loading: false,
      error: '',
    }),
    [connectADSAPIStore.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),

    [addAdsProfile.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [addAdsProfile.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      store: action.payload.store,
      loading: false,
      error: '',
    }),
    [addAdsProfile.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),

    [connectSPAAPIStore.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [connectSPAAPIStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      store: action.payload.store,
      profiles: action.payload.profiles,
      loading: false,
      error: '',
    }),
    [connectSPAAPIStore.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),

    [getStore.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload,
      store: action.payload.store,
      loading: false,
      error: '',
    }),
    [getStore.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      success: false,
      loading: false,
      message: action.payload,
    }),

    [getStores.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getStores.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      stores: action.payload.stores,
      selectedStore: action.payload.selectedStore,
      loading: false,
      error: '',
    }),
    [getStores.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      success: false,
      loading: false,
      message: action.payload,
    }),

    [getAdsStoreProfiles.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getAdsStoreProfiles.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      profiles: action.payload.profiles,
      loading: false,
      error: '',
    }),
    [getAdsStoreProfiles.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      profiles: [],
      success: false,
      loading: false,
      message: action.payload,
    }),
  },
});

const { reducer, actions } = store;

export const {
  resetState,
  updateAccountInfo,
  resetUpdateAccountInfo,
  updateSelectedStore,
  updateStore,
} = actions;

export default reducer;
