import Styled from 'styled-components';

const InputWrapper = Styled.div`    
 margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}` : '24px')};

 .outlined-input{
    height: ${(props) => (props.height ? `${props.height}` : '57px')};
    border-radius:  ${(props) => (props.borderRadius ? `${props.borderRadius}` : '13px')};;
    font-size: ${({ theme }) => theme.colors.baseFontSize};
    font-weight: ${({ theme }) => theme['semibold-weight']};
    color: ${({ theme }) => theme.colors.tableBody};
    padding-right:0px!important;
    letter-spacing:0;
    input{
       padding:8px 12px;
       text-align: ${(props) => (props.align ? `${props.align}` : 'left')};
           &::placeholder{
            font-size:16px;
            color: ${({ theme }) => theme.colors.labelColor};
          opacity: 0.5;
    }
    }

     padding-right: 10px;
 }
 .table-input-small{
  background: ${({ theme }) => theme.colors.background};
  input{
    height:30px;
    padding:0;
    text-align:center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.bodyText};
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: ${({ theme }) => theme.colors.background};
  }
  &:hover{
    .MuiOutlinedInput-notchedOutline{
      border-color:#d9d9d9;
    }
  }
 }
 .helper-text{
   font-size: 10px;
    color: ${({ theme }) => theme.colors.dangerColor};
   margin-left: 0;
   position:absolute;
   letter-spacing:0;
    bottom: -18px;
    margin-left: 0;
 }
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 1;
    display: block;
    background: url("https://european-outdoors.s3.amazonaws.com/date-icon.svg") no-repeat;
    width: 12px;
    height: 12px;
    margin-top:6px;
}
`;

export { InputWrapper };
