import React from 'react';

import { Box, Stack, Grid } from '@mui/material';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import Button from '../../../components/button/index';

function AccountConnected1({ onClick }) {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item md={6}>
        <Box
          width="64px"
          height="64px"
          bgcolor="#ECFDF3"
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: '50px' }}
        >
          <Box
            width="50px"
            height="50px"
            bgcolor="#D1FADF"
            sx={{ borderRadius: '50px' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <TaskAltOutlinedIcon sx={{ color: '#039855' }} />
          </Box>
        </Box>
        <Stack alignItems="flex-start">
          <Box
            component="h3"
            mb={2.2}
            className="m-0"
            fontFamily="'Inter', sans-serif"
          >
            Selling account connected!
          </Box>
          <Box
            mb={2}
            fontSize="14px"
            color="#667085"
            fontFamily="'Inter', sans-serif"
          >
            Great! Your selling account has been successfully connected to
            Melamdo.
          </Box>
        </Stack>
        <Box textAlign="right" mt={2} mb={2}>
          <Button
            variant="contained"
            text="Next"
            width="78px"
            height="40px"
            onClick={onClick}
          />
        </Box>{' '}
      </Grid>
    </Grid>
  );
}

export default AccountConnected1;
