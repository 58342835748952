export const isValidEmail = (email) =>
  /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const getDefaultProfiles = (profiles) => {
  let profilesObj = {};
  Object.keys(profiles).map((pKey) => {
    profilesObj[pKey] = {
      arr: profiles[pKey].map((profile) => {
        return {
          ...profile,
          selected: true,
        };
      }),
      selected: false,
    };
  });
  return profilesObj;
};

export const getFoundRegions = (stores = []) => {
  const regions = [];
  stores.forEach((store) => {
    const isRegionFound = regions.some((region) => region === store.region);
    if (!isRegionFound) {
      regions.push(store.region);
    }
  });
  return regions;
};
