import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBaseUrl, setAuthToken } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const getDashboardData = createAsyncThunk(
  '/dashboard/summary+graph-data+products-summary',
  async (data = {}, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token;
      const selectedStore = data.storeId || getState()?.store?.selectedStore;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const [summaryRes, pSummaryRes, graphRes] = await axios.all([
          axios.get(`/dashboard/summary?storeId=${selectedStore}`),
          axios.get(`/dashboard/products-summary?storeId=${selectedStore}`),
          axios.get(`/dashboard/graph-data?storeId=${selectedStore}`),
        ]);
        return {
          summary: summaryRes?.data?.summary || {},
          productsSummary: pSummaryRes?.data?.productsSummary || {},
          graphData: graphRes?.data?.graphData || [],
        };
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

export const getSummary = createAsyncThunk(
  '/dashboard/summary',
  async (data, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const response = await axios.get('/dashboard/summary');
        return response.data;
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

export const getGraphData = createAsyncThunk(
  '/dashboard/graph-data',
  async (data, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const response = await axios.get('/dashboard/graph-data');
        return response.data;
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

export const getProductsSummary = createAsyncThunk(
  '/dashboard/products-summary',
  async (data, { rejectWithValue, getState }) => {
    try {
      const melamdo_token = getState()?.auth?.melamdo_token;
      if (melamdo_token) {
        setAuthToken(melamdo_token);
        const response = await axios.get(
          `/dashboard/products-summary?storeId=${data.storeId}&marketplaceId=${data.marketplaceId}&startDate=${data.startDate}&endDate=${data.endDate}`
        );
        return response.data;
      } else return rejectWithValue('Unauthorized');
    } catch (err) {
      if (err.response && err.response.data) {
        const { status, data = {} } = err?.response || {};
        const errorMessage = status === 401 ? 'Unauthorized' : data?.message;
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('Network Error');
    }
  }
);

const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    success: false,
    message: '',
    loading: false,
    error: '',
    summary: {},
    graphData: {},
    productsSummary: [],
  },
  reducers: {
    resetState: (state) => ({
      ...state,
      success: false,
      message: '',
      loading: false,
      error: '',
    }),
  },
  extraReducers: {
    [getSummary.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getSummary.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      summary: action.payload.summary,
      loading: false,
      error: '',
    }),
    [getSummary.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      success: false,
      loading: false,
      message: action.payload,
    }),

    [getGraphData.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getGraphData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      graphData: action.payload.graphData,
      loading: false,
      error: '',
    }),
    [getGraphData.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      success: false,
      loading: false,
      message: action.payload,
    }),

    [getProductsSummary.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getProductsSummary.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      productsSummary: action.payload.productsSummary,
      loading: false,
      error: '',
    }),
    [getProductsSummary.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      success: false,
      loading: false,
      message: action.payload,
    }),

    [getDashboardData.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getDashboardData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      productsSummary: action.payload.productsSummary,
      summary: action.payload.summary,
      graphData: action.payload.graphData,
      loading: false,
      error: '',
    }),
    [getDashboardData.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      success: false,
      loading: false,
      message: action.payload,
    }),
  },
});

const { reducer, actions } = dashboard;

export const { resetState } = actions;

export default reducer;
