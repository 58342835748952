import React from 'react';
import Dialog from '@mui/material/Dialog';

const Modal = (props) => {
  const {
    show, children, onClose, width, fullWidth
  } = props;

  return (
    <Dialog
      open={show}
      fullWidth={fullWidth}
      maxWidth={width}
      onClose={onClose}
      sx={{ padding: '24px 38px', minWidth: 362 }}
    >
      {children}
    </Dialog>
  );
};

export default Modal;