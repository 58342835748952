import React, { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../button/index';
import { REGIONS } from '../../utils/constants';
import { getFoundRegions } from '../../utils/utils';
import {
  updateSelectedStore,
  getStores,
  resetUpdateAccountInfo,
} from '../../redux/slice/storeSlice';

function StoreFlyout({ onBlur }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stores, selectedStore } = useSelector((state) => state.store);

  const [localRegion, setLocalRegion] = useState();

  useEffect(() => {
    if (selectedStore) {
      const selectedStoreObj = stores.find(
        (store) => store._id === selectedStore
      );
      dispatch(
        updateSelectedStore({
          selectedStore: selectedStoreObj._id,
          store: selectedStoreObj,
        })
      );
      setLocalRegion(
        getFoundRegions(stores).find((r) => r === selectedStoreObj.region)
      );
    } else {
      dispatch(
        updateSelectedStore({
          selectedStore: stores[0]._id,
          store: stores[0],
        })
      );
      setLocalRegion(getFoundRegions(stores)[0]);
    }
  }, [stores]);

  useEffect(() => {
    dispatch(getStores());
  }, []);

  return (
    <Box
      position="absolute"
      right="-453px"
      bgcolor="#fff"
      boxShadow="0 0 20px rgba(16, 24, 40, 0.08)"
      width="409px"
      height="288px"
      top="0px"
      zIndex={100}
    >
      {stores.length > 0 && (
        <Box display="flex" gap={1}>
          <Box
            bgcolor="#E4E4E7"
            width="64px"
            py={2}
            minHeight="259px"
            maxHeight="277px"
            display="flex"
            justifyContent="center"
          >
            <Stack>
              {getFoundRegions(stores).map((region, index) => {
                return (
                  <Box
                    key={index}
                    className="pointer"
                    width="40px"
                    height="40px"
                    bgcolor="#fff"
                    borderRadius="8px"
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #66708580"
                    onClick={() => setLocalRegion(region)}
                  >
                    <img src={REGIONS[region]} width="18px" alt="no-country" />
                  </Box>
                );
              })}
              <Box
                className="pointer"
                width="40px"
                height="40px"
                bgcolor="#fff"
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="1px solid #66708580"
                onClick={() => {
                  navigate('/onBoarding');
                  localStorage.setItem('selectedItem', '');
                  dispatch(
                    updateSelectedStore({
                      selectedStore: '',
                      store: {},
                    })
                  );
                  dispatch(
                    resetUpdateAccountInfo({
                      name: '',
                      region: '',
                      storeType: 'ads',
                      authCode: '',
                      sellerId: '',
                      activeStep: 0,
                      randomState: `${Math.random()}${new Date().getSeconds()}`,
                      state: '',
                      onBoardingWithLayout: true,
                    })
                  );
                  onBlur();
                }}
              >
                <AddCircleOutlineOutlinedIcon
                  sx={{ fontSize: '12px', color: '#667085' }}
                />
              </Box>
            </Stack>
          </Box>
          <Box py={1.5} width="320px">
            <Box color="#18181B" fontSize="12px">
              Account
            </Box>
            {stores
              .filter((store) => store?.region === localRegion)
              .map((store, index) => {
                const isSelected = selectedStore === store._id;
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    bgcolor={isSelected ? '#D3D3D3' : '#F7F8F9'}
                    p={2}
                    className="pointer"
                    onClick={() => {
                      dispatch(
                        updateSelectedStore({
                          selectedStore: store._id,
                          store: store,
                        })
                      );
                    }}
                  >
                    <Box color="#18181B" fontSize="14px">
                      {store.name || ''}
                    </Box>
                    {isSelected && (
                      <SettingsOutlinedIcon
                        sx={{ color: '#667085' }}
                        fontSize="12px"
                      />
                    )}
                  </Box>
                );
              })}
            <Button
              text="Add Account"
              variant="contained"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              height="40px"
            />
            <Button
              text="Create Manager Account"
              variant="text"
              height="40px"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default StoreFlyout;
