import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';

import authSlice from '../slice/authSlice';
import storeSlice from '../slice/storeSlice';
import dashboardSlice from '../slice/dashboardSlice';

const storesTransform = createFilter('stores', ['storeName', 'spApiRegion']);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'store'],
  transforms: [storesTransform],
};

const reducers = combineReducers({
  auth: authSlice,
  store: storeSlice,
  dashboard: dashboardSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  devTools: true,
});
