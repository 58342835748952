import React, { useEffect } from 'react';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Grid, Stack } from '@mui/material';
import Button from '../../components/button';

import { setAuthToken } from '../../config/axios-configuration';
import { updatePreSignUpToken } from '../../redux/slice/authSlice';

import Logo from '../../static/images/logo.svg';

const EmailConfirmed = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const emailVerifyToken = new URLSearchParams(search)?.get('token') || '';
    dispatch(updatePreSignUpToken(emailVerifyToken));
    if (emailVerifyToken) {
      localStorage.setItem('emailToken', emailVerifyToken);
      setAuthToken(emailVerifyToken);
    }
  }, [search]);

  return (
    <>
      <Box textAlign="center" pt={60 / 8}>
        <img src={Logo} alt="no-logo" />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={4}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              mt={74 / 8}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="24px"
            >
              <Box
                width="64px"
                height="64px"
                bgcolor="#ECFDF3"
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: '50px' }}
              >
                <Box
                  width="50px"
                  height="50px"
                  bgcolor="#D1FADF"
                  sx={{ borderRadius: '50px' }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <TaskAltOutlinedIcon sx={{ color: '#039855' }} />
                </Box>
              </Box>
              <Stack alignItems="flex-start">
                <Box
                  component="h3"
                  mb={2.2}
                  className="m-0"
                  fontFamily="'Inter', sans-serif"
                >
                  Email Confirmed
                </Box>
                <Box
                  mb={2}
                  fontSize="14px"
                  color="#667085"
                  fontFamily="'Inter', sans-serif"
                >
                  Thanks for confirming your email, now let’s get started to
                  create your account.
                </Box>
              </Stack>
              <Stack pt={2} direction="row" justifyContent="end">
                <Button
                  variant="contained"
                  height="40px"
                  text="Get Started"
                  onClick={() => navigate('/auth/sign-up')}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default EmailConfirmed;
