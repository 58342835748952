const fsxl = '48px';
const fslg = '32px';
const fsxs = '13px';
const fsx = '14px';
const fsXxs = '11px';
const bxShaddow = '6px 6px 12px rgba(0, 0, 0, 0.16)';

const theme = {
  name: 'theme',
  colors: {
    primaryColor: '#0948A7', // primary color for all components
    onPrimary: '#fff',
    listColor: '#dfdfdf',
    secondaryColor: '#667085',
    bodyColor:'#f8fafc',
    secondaryVariant: '#E6E6E6', // secondary color for all components
    onSecondary: '#000000',
    background: '#F8F8F8',
    onBackground: '#000000',
    surface: '#151A26',
    bodyText: '#5A5F7D',
    bodyTextVariant: '#4E5969',
    labelColor: '#979797',
    borderColor: '#2E3846',
    checkBoxColor: '#002050',
    dangerColor: '#EF4444',
    inputBorder: '#D9D9D9',
    tableBody: '#272B41',
    informationText: '#00B2FF',
    // Accent Colors
    accentColorError: '#F31819',
    onaccentColorError: 'rgba(230, 31, 0, 0.05)',
    accentColorSuccess: '#22C55E',
    accentOutlineSuccess: 'rgba(15, 182, 0, 0.05)',
    accentColorWarning: '#F49300',
    accentOutlineWarning: 'rgba(244, 147, 0, 0.05)',
    baseFontSizeXl: fsxl,
    baseFontSizeLg: fslg,
    baseFontSize: fsxs,
    baseFontSizeMediumSmal: fsx,
    baseFontSizeXs: fsXxs,
    baseFontSizeSmall: '11px',
    boxShadow: bxShaddow
  }
};

export default theme;
