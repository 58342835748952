import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';
import { getStores } from './storeSlice';

const axios = axiosBaseUrl();

export const preSignUp = createAsyncThunk(
  '/auth/pre-sign-up',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/pre-sign-up', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          error: err?.response?.data?.message,
          message: err?.response?.data?.message,
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const signUp = createAsyncThunk(
  '/auth/sign-up',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/sign-up', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        const { status } = err.response;
        return rejectWithValue({
          error:
            status === 401
              ? { error: 'Unauthorized' }
              : err?.response?.data?.message,
          message:
            status === 401
              ? { error: 'Unauthorized' }
              : err?.response?.data?.message,
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const signIn = createAsyncThunk(
  '/auth/sign-in',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/auth/sign-in', data);
      await dispatch(getStores({token: response?.data?.token}));
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          error: err?.response?.data?.message.error,
          message: err?.response?.data?.message.error,
        });
      }
      return rejectWithValue({
        error: 'Network Error',
        message: 'Network Error',
      });
    }
  }
);

export const forgotPassword = createAsyncThunk(
  '/auth/forgot-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/forgot-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          error: err?.response?.data?.message,
          message: err?.response?.data?.message,
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const resetPassword = createAsyncThunk(
  '/auth/reset-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/reset-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        const { status } = err.response;
        return rejectWithValue({
          error:
            status === 401
              ? { error: 'Unauthorized' }
              : err?.response?.data?.message,
          message:
            status === 401
              ? { error: 'Unauthorized' }
              : err?.response?.data?.message,
        });
      }
      return rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    success: false,
    message: '',
    loading: false,
    error: '',
    user: {},
  },
  reducers: {
    LogOut: () => ({
      success: false,
      loading: false,
      message: '',
    }),
    updatePreSignUpToken: (state, payload) => {
      return {
        ...state,
        'pre_signup_melamdo_token': payload.payload,
      };
    },
    resetState: (state) => ({
      ...state,
      success: false,
      message: '',
      loading: false,
      error: '',
    }),
  },
  extraReducers: {
    [preSignUp.pending]: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [preSignUp.fulfilled]: (state, action) => ({
      ...state,
      success: action.payload.success,
      message: action.payload.message,
      loading: false,
      error: '',
    }),
    [preSignUp.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),

    [signUp.pending]: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [signUp.fulfilled]: (state, action) => ({
      ...state,
      success: action.payload.success,
      message: action.payload.message,
      'melamdo_token': action.payload.token,
      user: action.payload.user,
      loading: false,
      error: '',
    }),
    [signUp.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),

    [signIn.pending]: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [signIn.fulfilled]: (state, action) => ({
      ...state,
      success: action.payload.success,
      message: action.payload.message,
      'melamdo_token': action.payload.token,
      user: action.payload.user,
      loading: false,
      error: '',
    }),
    [signIn.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error,
      success: false,
      loading: false,
      message: action.payload.error,
    }),

    [forgotPassword.pending]: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [forgotPassword.fulfilled]: (state, action) => ({
      ...state,
      success: action.payload.success,
      message: action.payload.message,
      loading: false,
      error: '',
    }),
    [forgotPassword.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),

    [resetPassword.pending]: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    [resetPassword.fulfilled]: (state, action) => ({
      ...state,
      success: action.payload.success,
      message: action.payload.message,
      loading: false,
      error: '',
    }),
    [resetPassword.rejected]: (state, action) => ({
      ...state,
      error: action.payload.error?.error || 'Network error',
      success: false,
      loading: false,
      message: action.payload.error?.error,
    }),
  },
});

const { reducer, actions } = auth;

export const { SetState, LogOut, resetState, updatePreSignUpToken } = actions;

export default reducer;
