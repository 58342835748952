import React from 'react';
import {Box,Avatar} from '@mui/material';
import HeaderWrapper from './style';
import HeaderLogo from '../../static/images/header-logo.svg';
import SearchInput from '../searchInput/index';
import Bell from '../../static/images/Bell.svg';
import Question from '../../static/images/Question-mark.svg';
import Setting from '../../static/images/settings.svg';
import Button from '../button/button.styled';
import AvatarImg from '../../static/images/avatar.jpeg'; 
const Header=()=>{
  return(
    <HeaderWrapper>
      <Box display="flex" justifyContent="space-between">
        <Box  display="flex">
          <img src={HeaderLogo} alt="no-logo" />
          <Box ml={36}>
            <SearchInput placeholder="Search" height="32px" iconFontSize={16} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={1.5}>
          <Button variant="text" height="32px" className='icon-button' startIcon={<img src={Bell} alt="no-icon" />} classNamr="icon-button" />
          <Button variant="text" height="32px" className='icon-button' startIcon={<img src={Question} alt="no-icon" />} classNamr="icon-button" />
          <Button variant="text" height="32px" className='icon-button' startIcon={<img src={Setting} alt="no-icon" />} classNamr="icon-button" />
          <Avatar   sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={AvatarImg} />
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;