import React, { useState } from 'react';
import { Box, Grid, TableRow, TableCell, Stack, Link, Divider } from '@mui/material';
import Button from '../../../../components/button/index';
import Table from '../../../../components/table';
import Pagination from '../../../../components/pagination/index';
import Tooltip from '../../../../components/tooltip/index';
import Rating from '../../../../components/rating/index';
import { BsFunnel,BsGraphUpArrow } from 'react-icons/bs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchInput from '../../../../components/searchInput';
// images
import InventoryImg from '../../../../static/images/inventory.svg';
//
import {parentHeader} from '../../../../constant/index';

const Parent = () => {
  const [selectedStar,setSelectedStar]=useState(3);
  function createData(
    product,
    category,
    rating,
    days,
    stock,
  ) {
    return {
      product,
      category,
      rating,
      days,
      stock,
    };
  }
  const data = [];
  for (let i = 0; i <= 10; i += 1) {
    data.push(
      createData(
        <Stack direction="row" spacing={1} alignItems="center">
          <img src={InventoryImg} alt="no-product" />
          <Stack spacing={0.5} maxWidth="250px">
            <Link
              selectedItem color="#0948A7">Electric Shaver for Men Wet Dry Foil Mens Electric Razor</Link>
            <Box fontSize="12px" color="#71717A" display="flex" alignItems="center">
                            FBA:sdb8qy7r7eg 
              <Tooltip title="info here">
                <InfoOutlinedIcon className='pointer' sx={{color: '#FF922E',fontSize: '12px',marginLeft:'4px',marginRight: '4px', marginTop:'4px'}} />
              </Tooltip>
                            8 of 9 SKU
            </Box>
          </Stack>
        </Stack>,
        <Stack spacing={1.5}>
          <Box display="flex" gap={1}>
            <Link underline="none" className="text-ellipse" selectedItem color="#0948A7">Kitchen & Dikkkk</Link>
            <Box color="#71717A" selectedItem>#15,076</Box>
            <Box><BsGraphUpArrow className='fs-13 pointer color-secondary' /></Box>
          </Box> 
          <Box display="flex" gap={1}>
            <Link underline="none" selectedItem color="#0948A7">Manual Pasta Maker</Link>
            <Box color="#71717A" selectedItem>#22</Box>
          </Box> 
        </Stack>,
        <Stack>
          <Rating value={selectedStar} onChange={(e)=>setSelectedStar(e.target.value)} />
          <Box selectedItem color="#535A6A" display="flex" alignItems="center" gap={1}> <Box component="span" fontWeight="600">4.4</Box>  (2,992) <BsGraphUpArrow className='fs-13 pointer color-secondary' /></Box>
        </Stack>,
        <Box selectedItem display="flex" alignItems="center" color="#535A6A">N/A
          <Tooltip title="info here">
            <InfoOutlinedIcon className='pointer' sx={{color: '#667085',fontSize: '14px',marginLeft:'4px', marginRight: '4px',}} />
          </Tooltip>
        </Box>,
        <Stack spacing={1}>
          <Box color="#535A6A" selectedItem>396</Box>
          <Box selectedItem color="#22C55E" fontWeight="600" display="flex" alignItems="center" gap={1}>in stock </Box>
        </Stack>,

      )
    );
  }
  return (
    <>
      <Box bgcolor="#ECECEC" py={2} px={1.5}>
        <Grid container justifyContent="space-between">
          <Grid item md={4}>
            <SearchInput height="40px" placeholder="Search" />
          </Grid>
          <Grid>
            <Box textAlign="right">
              <Button startIcon={<BsFunnel />} height="36px" text="Filter" />
            </Box>
          </Grid>

        </Grid>

      </Box>
      <Box border="1px solid #E4E4E7" sx={{borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}>
        <Table tableHeader={parentHeader} height="422px" >
          {data.map((row) => (
            <TableRow
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.product}
              </TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.rating}</TableCell>
              <TableCell>{row.days}</TableCell>
              <TableCell>{row.stock}</TableCell>
            </TableRow>
          ))}
        </Table>
        <Divider />
        <Box p={3}>
          <Pagination />
        </Box>
      </Box>
    </>

  );
};
export default Parent;