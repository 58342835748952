import React from 'react';
import ButtonWrapper from './button.styled';

const Index = (props) => {
  const {
    variant,
    type,
    className,
    text,
    disabled,
    onClick,
    startIcon,
    width,
    height,
    borderRadius,
    style,
    id,
    endIcon,
    letterSpacing,
    padding,
    color,
    fontSize
  } = props;
  return (
    <ButtonWrapper
      id={id}
      size="small"
      variant={variant}
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      width={width}
      height={height}
      style={style}
      borderRadius={borderRadius}
      color={color}
      letterSpacing={letterSpacing}
      padding={padding}
      fontSize={fontSize}
    >
      {text}
    </ButtonWrapper>
  );
};
export default Index;
