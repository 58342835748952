import React, { useState, useEffect } from 'react';

import { Box, Grid, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

import { setAuthToken } from '../../../config/axios-configuration';

import Button from '../../../components/button';
import Input from '../../../components/inputs/input';
import Logo from '../../../static/images/logo.svg';

import { resetPassword, resetState } from '../../../redux/slice/authSlice';

const EmailConfirmed = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, success } = useSelector(
    (state) => state.auth
  );

  const [inputValue, setInputValues] = useState({
    password: null,
    newPassword: null,
  });

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    success && navigate('/auth/password-update');
  }, [navigate, success]);

  useEffect(() => {
    if (error && message) {
      toast.error(message);
    }
  }, [error, message]);

  useEffect(() => {
    const emailVerifyToken = new URLSearchParams(search)?.get('token') || '';
    if (emailVerifyToken) {
      localStorage.setItem('emailToken', emailVerifyToken);
      setAuthToken(emailVerifyToken);
    }
  }, [search]);

  const inputChangeHandle = (e) => {
    const { value, name } = e.target || {};
    setInputValues({
      ...inputValue,
      [name]: value,
    });
  };

  return (
    <>
      <Box textAlign="center" pt={60 / 8}>
        <img src={Logo} alt="no-logo" />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={4}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              mt={74 / 8}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="24px"
            >
              <Stack alignItems="flex-start">
                <Box
                  component="h3"
                  mb={2.2}
                  className="m-0"
                  fontFamily="'Inter', sans-serif"
                >
                  Enter a new password
                </Box>
              </Stack>
              <Box textAlign="left">
                <Input
                  label="New Password"
                  placeholder="Enter new password"
                  width="100%"
                  borderRadius="8px"
                  name="password"
                  onChange={inputChangeHandle}
                  value={inputValue.password}
                  type="password"
                />
                <Input
                  label="Confirm New Password"
                  placeholder="Re-enter new password"
                  width="100%"
                  borderRadius="8px"
                  name="newPassword"
                  onChange={inputChangeHandle}
                  value={inputValue.newPassword}
                  type="password"
                />
              </Box>
              <Box
                display="flex"
                gap={2}
                pt={2}
                direction="row"
                justifyContent="end"
              >
                <Button
                  variant="contained"
                  height="40px"
                  disabled={
                    !(
                      inputValue.password &&
                      inputValue.password === inputValue.newPassword
                    )
                  }
                  text={
                    loading ? (
                      <div>
                        <CircularProgress
                          color="inherit"
                          sx={{
                            width: '30px !important',
                            margin: '10px 12px 4px 12px',
                            height: '30px !important',
                          }}
                        />
                      </div>
                    ) : (
                      'Update Password'
                    )
                  }
                  onClick={() => {
                    dispatch(resetPassword({ password: inputValue.password }));
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default EmailConfirmed;
