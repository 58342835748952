import styled from 'styled-components';

const StepperStyle = styled.div`
.MuiStep-root {
    .MuiStepLabel-root {
        .MuiStepLabel-label  {
            color:#71717A;
            max-width: 110px;
            font-size:14px;
            margin: 16px auto;
            &.Mui-active {
                color: #0948A7;
            }
        }
    }
    .MuiSvgIcon-root{
        &.Mui-completed {
            color: #0948A7;
        }
        &.Mui-active{
            color: #0948A7;
        }
    }
}
    
`;
export default StepperStyle;
