import Styled from 'styled-components';

const HeaderWrapper = Styled.div`
    background-color:#fff;
    padding:16px 32px;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export default HeaderWrapper;
