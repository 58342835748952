import React from 'react';
import {Box,Stack,Link,TableRow,TableCell,Divider} from '@mui/material';
// icons
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {BsPlusLg,BsThreeDots} from 'react-icons/bs';
// component
import AppHeader from '../../components/appHeader/index';
import Button from '../../components/button';
import Table from '../../components/table';
import Pagination from '../../components/pagination';
// images
import InventoryImg from '../../static/images/inventory.svg';
// constant
import { InformationHeader } from '../../constant';

const Inventory =()=>{
  function createData(
    product,
    cogs,
    details,
  ) {
    return {
      product,
      cogs,
      details,
    };
  }
  const data = [];
  for (let i = 0; i <= 10; i += 1) {
    data.push(
      createData(
        <Stack direction="row" spacing={1} alignItems="center">
          <img src={InventoryImg} alt="no-product" />
          <Stack spacing={0.5} maxWidth="250px">
            <Link
              selectedItem color="#0948A7">Electric Shaver for Men Wet Dry Foil Mens Electric Razor</Link>
            <Box fontSize="12px" color="#71717A" display="flex" alignItems="center">
                        FBA:sdb8qy7r7eg
              <ContentCopyOutlinedIcon className="pointer" sx={{color:'#000',fontSize:'12px',marginLeft:'8px',marginRight:'8px'}} />
                        GR-PastalRed USA
            </Box>
          </Stack>
        </Stack>,
        <Button startIcon={<BsPlusLg  />} height="28px" text="COGS" />,
        <Box color="#535A6A" selectedItem><BsThreeDots /></Box>,
        <Box color="#535A6A" selectedItem>255</Box>,
      )
    );
  }

  return(
    <>
      <AppHeader pathname="Information" />
      <Box  border="1px solid #E4E4E7" mt={3} borderRadius="10px">
        <Table tableHeader={InformationHeader} height="240px">
          {data.map((row) => (
            <TableRow
              hover
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.product}
              </TableCell>
              <TableCell>{row.cogs}</TableCell>
              <TableCell>{row.details}</TableCell>
            </TableRow>
          ))}
        </Table>
        <Divider />
        <Box p={3}>
          <Pagination />
        </Box>
      </Box>
    </>

  );
};
export default Inventory;