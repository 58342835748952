import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import Button from '../../../components/button/index';
import Select from '../../../components/select/index';
import { setAuthToken } from '../../../config/axios-configuration';
import {
  resetState,
  connectSPAAPIStore,
} from '../../../redux/slice/storeSlice';
import {
  REGION_ITEMS,
  APPLICATION_ID,
  REDIRECT_URI,
} from '../../../utils/constants';

const ConnectSellerCentral = ({ inputChangeHandle, onClick }) => {
  const dispatch = useDispatch();

  const { accountInfo, store, message, error, loading } = useSelector(
    (state) => state.store
  );
  const { melamdo_token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (store?.spApi?.marketplaces?.length > 0) {
      onClick();
    }
  }, [store]);

  useEffect(() => {
    if (error && message && !loading) {
      toast.error(message);
      dispatch(resetState());
    }
  }, [message]);

  useEffect(() => {
    const { name, region, storeType, sellerId, state, randomState } =
      accountInfo;
    if (name && region && storeType && sellerId && state === randomState) {
      setAuthToken(melamdo_token);
      dispatch(connectSPAAPIStore({ ...accountInfo, storeType: 'sp-api' }));
    }
  }, [accountInfo]);

  const submitHandle = () => {
    let url;
    switch (accountInfo.region) {
    case 'eu':
      url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APPLICATION_ID}&version=beta&state=${accountInfo.randomState}&redirect_uri=${REDIRECT_URI}`;
      break;
    case 'na':
      url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APPLICATION_ID}&version=beta&state=${accountInfo.randomState}&redirect_uri=${REDIRECT_URI}`;
      break;
    default:
      url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APPLICATION_ID}&version=beta&state=${accountInfo.randomState}&redirect_uri=${REDIRECT_URI}`;
      break;
    }
    window.open(url, '_self');
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={6}>
            <Box
              bgcolor="#fff"
              borderRadius={3}
              mt={3}
              boxShadow="0px 0 10px rgba(0, 0, 0, 0.06)"
              padding="11px 32px"
            >
              <>
                <h3>Connect Selling Partner</h3>
                <Box component="span" color="#667085" fontSize="14px">
                  You must have a professional Amazon account for sellers in the
                  marketplace you’re connecting to.
                </Box>
                <Box textAlign="left" mt={3}>
                  <Select
                    label="Region"
                    placeholder="-Select Region"
                    name="region"
                    value={accountInfo.region}
                    menuItem={REGION_ITEMS}
                    handleChange={inputChangeHandle}
                    disabled={true}
                  />
                  <Box textAlign="right" mt={2} mb={2}>
                    <Button
                      variant="contained"
                      text="Connect Selling Partner"
                      width="230px"
                      height="40px"
                      onClick={submitHandle}
                    />
                  </Box>
                </Box>
              </>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ConnectSellerCentral;
