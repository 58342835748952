import React from 'react';

import Button from '../components/button';

function Logout({ children }) {
  return (
    <>
      <Button
        variant="contained"
        text="Logout"
        height="25px"
        style={{ position: 'absolute', right: '5px', top: '5px' }}
        onClick={() => {
          localStorage.clear();
          window.open('/', '_self');
        }}
      />
      {children}
    </>
  );
}

export default Logout;
