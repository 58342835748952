import React from 'react';
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputAdornment
} from '@mui/material';
// style
// images
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import { InputWrapper } from '../style';

const Index = (props) => {
  const {
    label,
    placeholder,
    onChange,
    value,
    width,
    height,
    helperText,
    name,
    minValue,
    marginBottom,
    type,
    disabled,
    multiline,
    maxRows,
    align,
    inputRef,
    defaultValue,
    className,
    onClick,
    borderRadius,
    dateRange,
  } = props;
  return (
    <InputWrapper
      height={height}
      marginBottom={marginBottom}
      align={align}
      borderRadius={borderRadius}
    >
      <FormControl sx={{ width }}>
        {label && <label>{label}</label>}
        <OutlinedInput
          inputProps={{ min: minValue }}
          disabled={disabled}
          id={name}
          name={name}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChange}
          className={`outlined-input ${className}`}
          value={value}
          type={type}
          error={!!helperText}
          multiline={multiline}
          defaultValue={defaultValue}
          rows={maxRows}
          inputRef={inputRef}
          sx={{
            '&.MuiOutlinedInput-root:hover': {
              border: 'none',
            },
          }}
          startAdornment={dateRange && (
            <InputAdornment position="start">
              <CalendarTodayOutlinedIcon />
            </InputAdornment>
          )}
        />
        {helperText && (
          <FormHelperText className="helper-text">{helperText}</FormHelperText>
        )}
      </FormControl>
    </InputWrapper>
  );
};

export default Index;
